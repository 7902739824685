import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {CognitoContext} from '../../contexts/CognitoContext';

import {CHETWODEBLUE, OzTable, OzIcon, OzContainer, OzRow, OzCol, OzCard, OzButton} from "@ozwol/webui";

import Meta from '../../components/Meta';
import PeriodTotal from '../../chunks/PeriodTotal';

import {checkPermission, formatError, convertNumber, getFormattedDate, getFormattedTime} from '../../helpers';

import AccountService from '../../services/AccountService';
import PageHeader from '../../components/PageHeader';
import HealthyWrapper from '../../components/HealthyWrapper';
import CardHeaderTitle from '../../components/CardHeaderTitle';


const CardPhrase = styled.div`
  display: flex;
  align-items: center;
`;

const CardPhraseText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #303030;

  display: flex;
  align-items: baseline;
`;
const CardPhraseSuperNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #303030;
  margin: 0px 5px;
`;

const RechargeIcon = styled.div`
  color: ${CHETWODEBLUE};
  margin-top: 2px;
  margin-bottom: -2px;
  & > span{
    font-size: 20px;
  }
`;

// const PlanDot = styled.div`
//   display: inline-block;
//   background-color: ${CHETWODEBLUE};
//   width: 11px;
//   height: 11px;
//   border-radius: 3px;
// `;

const NoLog = styled.div`
  text-align: center;
  padding: 50px;
  font-size: 12px;
  font-weight: 400;
`;

// const LabelComponent = styled.div`
//   width: 16px;
//   height: 16px;
//   display: flex;
//   justify-content: start;
//   align-items: center;
//   margin-bottom: -8px;
// `;


const Recharges = ({refScrollerPage}) => {
  const [user, setUser] = useState(null);
  const [period, setPeriod] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(-1);
  const [periodHistory, setPeriodHistory] = useState(null);
  const [recharges, setRecharges] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const {cognito} = useContext(CognitoContext);

  useEffect(() => {
    if(checkPermission("period", "read") && checkPermission("period", "list") && checkPermission("recharge", "list")){
      if(period === null || selectedPeriod !== period.sequenceNumber){
        AccountService.getPeriod({
          "includeRecharges": true
        }, selectedPeriod).then((response) => {
            setPeriod(response);
            setSelectedPeriod(response.sequenceNumber);
            // if(response.currentPlan && response.currentPlan.planType === "PAID"){   
              setRecharges(response.recharges);
            // }else{
            //   setRecharges(null);
            // }        
        }).catch((error) => {
          setFetchError(formatError(error));
        }); 
      }
    }
  }, [period, selectedPeriod, cognito]);

  useEffect(() => {

    if(!cognito.groups.includes("OzwolCoworker")){
      AccountService.getUser().then((response) => {
        setUser(response);
      }).catch((error) => {
        setFetchError(formatError(error));
      }); 
    }
    
    if(checkPermission("period", "read") && checkPermission("period", "list")){
      AccountService.listPeriods().then((response) => {
        if(response && response.result){
          setPeriodHistory(response.result.slice(0, 6).reverse());
        }
      }).catch((error) => {
        setPeriodHistory(null);
        setFetchError(formatError(error));
      });
    }
  }, [cognito]);



  return (
    <OzContainer size="extra">
      <Meta title={"Recharges"} />
      <PageHeader
        breadcrumbsIcon="nearby"
        breadcrumbsText={["Recharges", "Overall"]}
        refScrollerPage={refScrollerPage}
      />
      
      <HealthyWrapper error={fetchError}>
        <OzRow>

          <OzCol>
            {user && recharges ?
              <PeriodTotal
                numRecharges={recharges.length}
                totRecharges={recharges && recharges.length > 0 ? recharges.map(item => item.price).reduce((a, b) => a + b, 0) : 0}
                planName={period.currentPlan ? period.currentPlan.name : null}
                planPrice={period.currentPlan ? period.currentPlan.price : 0}
                isDeletingAccount={period.currentPlan.planType === "PAID" && !user.nextPlan}
              />
            : null }
          </OzCol>

             
        </OzRow>   
        <br/>
        <OzRow>
          {period && periodHistory && periodHistory.length > 1 ? periodHistory.map((val, i) => 
            <OzCol widthmd={2} key={i}>
              <OzButton
                width="100%"
                variant={selectedPeriod === val.sequenceNumber ? "silvertree-o" : "push-black"}
                onClick={() => setSelectedPeriod(val.sequenceNumber)}
              >
                {i === periodHistory.length -1 ?
                  "Current"
                :
                  getFormattedDate(val.startDate, false) + " - " + getFormattedDate(val.endDate, false)
                }
              </OzButton>
            </OzCol>
          ) : null }
        </OzRow>
        <br/>
        <OzRow>

          <OzCol>
            {recharges ?
              <OzCard
                height="100%"
                headerLeft={
                  <CardHeaderTitle 
                    title="Recharges" 
                    subtitle={<>{recharges.length} recharges in this period</>}
                  />
                }
                headerRight={
                  <CardPhrase>
                    <CardPhraseText>
                      Total sum of recharges
                      <CardPhraseSuperNumber>
                        {recharges.length > 0 ? convertNumber(recharges.map(item => item.creditsRecharged).reduce((a, b) => a + b, 0),0) : 0}
                      </CardPhraseSuperNumber>
                    </CardPhraseText>
                    <OzIcon name={"credits"} size="25px" />
                  </CardPhrase>
                }
              >

                {recharges.length > 0 ?
                  <OzTable
                    columns={[
                      "",
                      "Date",
                      "Time",
                      <center>Credits</center>,
                      <center>Cost</center>
                    ]}
                    values={recharges.map(item => ({values: [
                      <RechargeIcon><span className="material-symbols-outlined">nearby</span></RechargeIcon>,
                      getFormattedDate(item.createDatetime),
                      getFormattedTime(item.createDatetime, false),
                      <center>{convertNumber(item.creditsRecharged)}</center>,
                      <center>{convertNumber(item.price)+"€"}</center>
                    ]}))}
                  />
                :
                  <NoLog>No automatic charging during this period</NoLog>
                }
              </OzCard>
            : null }
          </OzCol>
        </OzRow>
      </HealthyWrapper>
    </OzContainer>
  )
}

export default Recharges;
