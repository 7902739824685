import React, {useContext} from 'react';
import styled from 'styled-components';

import {OverlayContext} from './../contexts/OverlayContext';

import {OzBreadcrumbs, OzContainer, OzButton, OzCard} from '@ozwol/webui';

const Wrapper = styled.div`
`;

const ButtonWithAlert = ({
	title,
	text,
	onConfirm = () => {}, 
	onCancel = () => {}, 
	children = null
}) => {
  const {addOverlay, removeOverlay} = useContext(OverlayContext);

  return (
		<>
			<Wrapper onClick={() => addOverlay({
				reference: "logout",
				content:
					<OzContainer size="small">
						<OzCard
							footerLeft={<OzButton onClick={() => {removeOverlay("logout"); onCancel();}} variant={"chetwodeblue-o"}>Back</OzButton>}
							footerRight={<OzButton onClick={() => {removeOverlay("logout"); onConfirm();}} variant={"bittersweet"}>Confirm</OzButton>}
						>
							<OzBreadcrumbs values={[
								{
									name: title
								}
							]} />
							<br/>
							{text}								
						</OzCard>
					</OzContainer>
			})}>
				{children}
			</Wrapper>
		</>
  )
}

export default ButtonWithAlert;
