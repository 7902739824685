import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {OverlayContext} from './../../contexts/OverlayContext';
// import styled from 'styled-components';

import {OzContainer, OzRow, OzCol, OzCard, OzInput, OzButton} from '@ozwol/webui';

import ProjectsService from '../../services/ProjectsService';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ModalSuccess from '../../components/ModalSuccess';
import ErrorMessage from '../../components/ErrorMessage';
import PromptedButton from '../../components/PromptedButton';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {checkPermission, formatError} from '../../helpers';

const Access = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const {projectUuid} = useParams();
  const [project, setProject] = useState(null);
  const [credentials, setCredentials] = useState(null);

  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);


  const getCredentials = useCallback(() => {
    ProjectsService.getCredentials(projectUuid).then((response) => {
      setCredentials(response);
    }).catch((error) => {
      setCredentials(null);
      setFetchError(formatError(error));
    });
  }, [projectUuid]);
  
  useEffect(() => {
    ProjectsService.get(projectUuid).then((response) => {
      setProject(response);
    }).catch((error) => {
      setProject(null);
      setFetchError(formatError(error));
    });
    getCredentials();
  }, [projectUuid, getCredentials]);

  const generateCredentials = useCallback(() => {
    setErrorMessage(null);
    ProjectsService.addCredentials(projectUuid).then((response) => {addOverlay({
      reference: "success",
      content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
      timeout: OVERLAY_TIMEOUT_SUCCESS
    });
      getCredentials();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [projectUuid, addOverlay, getCredentials]);

  const deleteCredentials = useCallback(() => {
    setErrorMessage(null);
    ProjectsService.deleteCredentials(projectUuid).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setCredentials(null);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [projectUuid, addOverlay]);

  return (
    <OzContainer size="extra">
      {project ? 
        <>
          <Meta title={project.name + " - Workspace access"} />
          <PageHeader
            breadcrumbsIcon="hub"
            breadcrumbsText={["Workspace access", project ? project.name : "", project ? project.uuid : ""]}
            refScrollerPage={refScrollerPage}
          />
        </>
      : null }

      <HealthyWrapper error={fetchError} loading={!project}>
        {project ?
          <OzRow>
            <OzCol>
              <OzCard
                footerRight={!(credentials && credentials.awsAccessKeyId)  ?
                  checkPermission("projectCredentials", "create") ? 
                    <OzButton onClick={() => generateCredentials()} variant={"silvertree"}>Generate credentials</OzButton>
                  : null
                :
                  
                  checkPermission("projectCredentials", "delete") ? 
                    <PromptedButton 
                      buttonVariant="bittersweet" 
                      buttonText={"Delete credentials"} 
                      prompt="DELETE"
                      onConfirm={() => deleteCredentials()} 
                    /> 
                  : null
                }
                headerLeft={
                  <CardHeaderTitle 
                    title={"Workspace access"}
                    subtitle={"Use accessKeyId and secretAccessKey to access to the remote bucket"}
                  />
                }
                headerSeparator={true}
              >
                <OzRow>
                  <OzCol widthmd="12">
                    <OzInput label="Uuid" width="100%" copyable disabled value={project.uuid} />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput label="Bucket name" width="100%" copyable disabled value={process.env.REACT_APP_DEFAULT_BUCKET_NAME} />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput label="Path" width="100%" copyable disabled value={process.env.REACT_APP_DEFAULT_BUCKET_NAME+"/"+project.uuid+"/"} />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput label="AWS access key id" width="100%" copyable type="password" toggleVisibility disabled value={credentials ? credentials.awsAccessKeyId : ""} />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput label="AWS secret access key" width="100%" copyable type="password" toggleVisibility disabled value={credentials ? credentials.awsSecretAccessKey : ""} />
                  </OzCol>
                </OzRow>

              </OzCard>
            </OzCol>
          </OzRow>
        : null}
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </HealthyWrapper>

    </OzContainer>
  );
}

export default Access;
