import React, {useState, useCallback, useEffect, useContext} from 'react';
// import axios from 'axios';
import styled from 'styled-components';
import {OverlayContext} from '../../../contexts/OverlayContext';

import {LILAC, OzCard, OzLoading} from "@ozwol/webui";

import DownloadService from '../../../services/DownloadService';

const Wrapper = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 350px;

  text-align: left;
  z-index: 999;

  & > *{
    box-shadow: #c1cce890 0px 3px 2px;
  }
`;

const Loader = styled.div`
  float: right;
`;


const DownloadPolling = ({uuid}) => {
  const {removeOverlay} = useContext(OverlayContext);
  const [text, setText] = useState(<><Loader><OzLoading bgcolor={LILAC} size="20px" /></Loader>Dowloading...</>);


	const check = useCallback((uuid) => {
    DownloadService.get(uuid).then((response) => {
      if(response.status === "SUCCESS"){
				const downloadLink = document.createElement('a');
        downloadLink.href = response.downloadUrl;
        // downloadLink.target = "_blank";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

				removeOverlay("downloadPolling");
			}else if(response.status === "ERROR"){
				setText(response.errorDescription)
			}else{
				setText(<><Loader><OzLoading bgcolor={LILAC} size="20px" /></Loader>Preparing your files...</>)
				setTimeout(() =>{
					check(uuid);
				}, 2000);
			}
    }).catch((error) => {
      // setFetchError(formatError(error));
    });
  }, [removeOverlay]);
  

  useEffect(() => {
		check(uuid);
  }, [uuid, check]);

  return (
    <Wrapper>
      <OzCard
        variant={"lilac"}
        headerLeft={"Downloading"}
      >
        {text}
      </OzCard>
    </Wrapper>
  )
}

export default DownloadPolling;
