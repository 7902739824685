import React, {useState, useEffect, useCallback, useContext} from 'react';
// import styled from 'styled-components';
import {OverlayContext} from './../../../contexts/OverlayContext';
import {OzRow, OzCol, OzCard, OzInput, OzButton} from '@ozwol/webui';
// import Container from './../../common/components/Container';

import AuthService from '../../../services/CognitoService';

import ModalSuccess from '../../../components/ModalSuccess';
import CardHeaderTitle from '../../../components/CardHeaderTitle';
import ErrorMessage from '../../../components/ErrorMessage';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../../config';

const ProfileChangePassword = ({item, refreshItem}) => {
  const {addOverlay} = useContext(OverlayContext);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(oldPassword.length === 0){
      valid = false;
    }
    if(newPassword.length === 0){
      valid = false;
    }
    if(newPassword !== newPassword2){
      valid = false;
    }
    setDisabled(!valid);
  }, [oldPassword, newPassword, newPassword2]);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    AuthService.changePassword(oldPassword, newPassword).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setOldPassword("");
      setNewPassword("");
    }).catch((error) => {
      setErrorMessage(error.message);
    });
  }, [oldPassword, newPassword, addOverlay]);  

  
  return (
    <>
      {localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ?
        <OzCard
          headerLeft={
            <CardHeaderTitle 
              title={"Change password"} 
            />
          }
          headerSeparator={true}
          footerRight={<OzButton variant={"chetwodeblue"} disabled>Submit</OzButton>}
        >
          <OzRow>
            <OzCol widthmd="4">
              <OzInput disabled label="Current password" type="password" width="100%" onChange={(val) => setOldPassword(val)} value={oldPassword} />
            </OzCol>
            <OzCol widthmd="4">
              <OzInput disabled label="New password" type="password" width="100%" onChange={(val) => setNewPassword(val)} value={newPassword} />
            </OzCol>
            <OzCol widthmd="4">
              <OzInput disabled label="Repeat new password" type="password" width="100%" onChange={(val) => setNewPassword2(val)} value={newPassword2} />
            </OzCol>
          </OzRow>
        </OzCard>
      :
        <OzCard
          headerLeft={"Change password"}
          headerSeparator={true}
          footerRight={<OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
        >
          <OzRow>
            <OzCol widthmd="4">
              <OzInput toggleVisibility label="Current password" type="password" width="100%" onChange={(val) => setOldPassword(val)} value={oldPassword} highlighted={oldPassword && oldPassword.length > 0} />
            </OzCol>
            <OzCol widthmd="4">
              <OzInput toggleVisibility label="New password" type="password" width="100%" onChange={(val) => setNewPassword(val)} value={newPassword} highlighted={newPassword && newPassword.length > 0} />
            </OzCol>
            <OzCol widthmd="4">
              <OzInput toggleVisibility label="Repeat new password" type="password" width="100%" onChange={(val) => setNewPassword2(val)} value={newPassword2} highlighted={newPassword2 && newPassword2.length > 0} />
            </OzCol>
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
        </OzCard>
      }
    </>
  )
}

export default ProfileChangePassword;
