import React, {useContext} from 'react';
import styled from 'styled-components';
import {OverlayContext} from './../contexts/OverlayContext';

import CardHeaderTitle from './../components/CardHeaderTitle';

import ModalNewCard from './ModalNewCard';
import ModalNewSepa from './ModalNewSepa';

import {CHETWODEBLUE, OzButton, OzCol, OzRow, OzCard} from '@ozwol/webui';

const Container = styled.div`
  width: 450px;
`;

const NotificationBlock = styled.div`
  padding: 20px;
/*  height: 500px; */
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
`;
const Method = styled.div`

  &:hover > div:first-child{
    border: 3px solid ${CHETWODEBLUE}99;
  }
  &:hover > div:last-child{
    color: ${CHETWODEBLUE};
  }

`;
const MethodBlock = styled.div`
  border-radius: 9px;
  background: ${CHETWODEBLUE}13;
  padding: 20px;
  height: 130px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  cursor: pointer;

  margin-top: 30px;

  border: 3px solid transparent;

  & > *{
    font-size: 60px;
  }
`;
const MethodName = styled.div`
  color: #01000B33;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
`;

const ModalNewPayment = () => {
  const {replaceOverlay, removeOverlay} = useContext(OverlayContext);

  return (
    <Container>

      <OzCard
        headerLeft={
          <CardHeaderTitle title={"New payment method"} />
        }
        headerSeparator={true}
        footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("payment-method")}>Back</OzButton>}
      >
        <NotificationBlock>

          <OzRow>
            <OzCol widthmd="6">
              <Method>
                  <MethodBlock onClick={() => replaceOverlay({
                    reference: "payment-method",
                    content: <ModalNewSepa />
                  },"payment-method")}>
                  <span className="material-symbols-outlined">account_balance</span>
                </MethodBlock>
                <MethodName>
                  Add Sepa debit source
                </MethodName>
              </Method>
            </OzCol>
            <OzCol widthmd="6">
              <Method>
                <MethodBlock onClick={() => replaceOverlay({
                  reference: "payment-method",
                  content: <ModalNewCard />
                },"payment-method")}>
                  <span className="material-symbols-outlined">credit_card</span>
                </MethodBlock>
                <MethodName>
                  Add new Credit Card
                </MethodName>
              </Method>
            </OzCol>
          </OzRow>
        </NotificationBlock>
      </OzCard>

    </Container>
  );

}

export default ModalNewPayment;
