import React, {useState, useEffect, useCallback, useContext} from 'react';
// import {Link} from 'react-router-dom';
// import styled from 'styled-components';
import {OverlayContext} from '../../contexts/OverlayContext';
import {CognitoContext} from '../../contexts/CognitoContext';
import {OzContainer, OzRow, OzCol, OzCard, OzButton} from '@ozwol/webui';

import AccountService from '../../services/AccountService';
import CognitoService from '../../services/CognitoService';

import ModalMFA1 from '../../chunks/ModalMFA1';
import ModalMFARemove from '../../chunks/ModalMFARemove';

// import Success from '../../components/Success';
import Meta from '../../components/Meta';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ErrorMessage from '../../components/ErrorMessage';

import ProfileChangePassword from './common/ProfileChangePassword';
import PageHeader from '../../components/PageHeader';

import {formatError} from '../../helpers';


const Security = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState();
  const [cognitoUser, setCognitoUser] = useState(null);
  const {cognito} = useContext(CognitoContext);

  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);
 

  const getItem = useCallback(() => {
    if(!cognito.groups.includes("OzwolCoworker")){
      AccountService.getUser().then((response) => {
        setItem(response);
      }).catch((error) => {
        setItem(null);
        setFetchError(formatError(error));
      });
    }else{
      AccountService.getCoworker(cognito.ParsedJwtToken.sub).then((response) => {
        setItem(response);
      }).catch((error) => {
        setItem(null);
        setFetchError(formatError(error));
      });
    }
  }, [cognito]);
      

  const getCognitoUser = useCallback(() => {
    setErrorMessage(null);
    CognitoService.getUser().then((response) => {
      setCognitoUser(response);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, []);
    
  useEffect(() => {
    getItem();
    getCognitoUser();
  }, [getItem, getCognitoUser]);


  return (
    <>
      <Meta title={"Security"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="security"
          breadcrumbsText={["Security", "Account"]}
          refScrollerPage={refScrollerPage}
        />

        <HealthyWrapper error={fetchError} loading={!item}>
          <OzRow>
            <OzCol>
              {item ? 
                <ProfileChangePassword
                  item={item}
                  refreshItem={() => getItem()}
                />
              : null}
            </OzCol>

            <OzCol>
              {/* {item ?  */}
                <OzCard
                  headerLeft={
                    <CardHeaderTitle 
                      title={"Multi-Factor Authentication"} 
                    />
                  }
                  headerSeparator={true}
                  // footerRight={!cognito.groups.includes("OzwolCoworker") ? <OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton> : null}
                >
                  <OzRow>
                    <OzCol widthmd="12">
                      Enhance your account security by adding an extra layer of protection. Even if your password is compromised, a second login step ensures your account remains secure. All you need to enable it is an app installed on your mobile device or computer.
                      <br/><br/>

                      {localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ?
                        <>
                          <OzButton disabled variant={"bittersweet"}>Configure/Remove MFA</OzButton>
                        </>
                      :
                        <>
                          {item && cognitoUser? 
                            <>
                              {cognitoUser.UserMFASettingList && cognitoUser.UserMFASettingList.length > 0 ? 
                                <OzButton
                                  onClick={() => addOverlay({
                                    reference: "mfa",
                                    content: <ModalMFARemove 
                                      user={item} 
                                      onSuccess={() => {
                                        getItem();
                                        getCognitoUser();
                                      }} 
                                    />,
                                    isCloseable: false
                                  })} 
                                  variant={"bittersweet"}
                                >
                                  Remove MFA
                                </OzButton>
                              :
                                <OzButton 
                                  onClick={() => addOverlay({
                                    reference: "mfa",
                                    content: <ModalMFA1 
                                      user={item} 
                                      onSuccess={() => {
                                        getItem();
                                        getCognitoUser();
                                      }} 
                                    />,
                                    isCloseable: false
                                  })} 
                                  variant={"chetwodeblue"}
                                >
                                  Configure MFA
                                </OzButton>
                              }
                            </>
                          : null}
                        </>
                      }
                    
                    </OzCol>
                  </OzRow>
                  <ErrorMessage>
                    {errorMessage}
                  </ErrorMessage>
                </OzCard>
              {/* : null} */}
            </OzCol>

            
          </OzRow>
        </HealthyWrapper>

      </OzContainer>
    </>
  )
}

export default Security;
