import React, { useState, useContext, useEffect} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import styled from 'styled-components';

import {CHETWODEBLUE, OzContainer, OzButton, OzCol, OzRow, OzInput, OzTextarea, OzCard, OzCheckbox, OzSelect, OzCopyable, OzLoading} from "@ozwol/webui";

import DestinationsService from '../../services/DestinationsService';

import ModalSuccess from '../../components/ModalSuccess';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import SuccessMessage from '../../components/SuccessMessage';
import HealthyWrapper from '../../components/HealthyWrapper';
import PromptedButton from '../../components/PromptedButton';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, testValidField} from '../../helpers';

const Link = styled.span`
  color: ${CHETWODEBLUE};
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
`;

const DestinationsModalNew = ({onSuccess, data = null}) => {
  const {replaceOverlay, removeOverlay} = useContext(OverlayContext);

  const [item, setItem] = useState(data ? data : {
    name: "",
    description: "",
    active: true,
    connectionDetails: {
      includeProjectInRemotePath: true
    }
  });
  const [regions, setRegions] = useState([]);

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isGenerateKeys, setIsGenerateKeys] = useState(false);

  const [testLoading, setTestLoading] = useState(false);


  useEffect(() => {
    setErrorMessage(null);
    setSuccessMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(item.name, true)){
        valid = false;
      }
      if(!testValidField(item.connectionDetails.destinationType, true)){
        valid = false;
      }
      switch(item.connectionDetails.destinationType){
        case "AWS":
          if(!testValidField(item.connectionDetails.bucket, true)){
            valid = false;
          }
          if(!testValidField(item.connectionDetails.region, true)){
            valid = false;
          }
          if(item.connectionDetails.credentials){
            
            if(!testValidField(item.connectionDetails.credentials.logonType, true)){
              valid = false;
            }
            if(item.connectionDetails.credentials.logonType === "credentials"){            
              if(!testValidField(item.connectionDetails.credentials.awsAccessKeyId, true)){
                valid = false;
              }          
              if(!testValidField(item.connectionDetails.credentials.awsSecretAccessKey, true)){
                valid = false;
              }
            }
          }
          break;
        case "Azure":
          if(!testValidField(item.connectionDetails.storageAccountName, true)){
            valid = false;
          }
          if(!testValidField(item.connectionDetails.container, true)){
            valid = false;
          }
          if(item.connectionDetails.credentials){            
            // if(!testValidField(item.connectionDetails.credentials.logonType, true)){
            //   valid = false;
            // }
            // if(item.connectionDetails.credentials.logonType === "credentials"){            
              if(!testValidField(item.connectionDetails.credentials.accessKey, true)){
                valid = false;
              }
            // }
          }
          break;
        case "Cloudflare":
          if(!testValidField(item.connectionDetails.accountId, true)){
            valid = false;
          }
          if(!testValidField(item.connectionDetails.bucket, true)){
            valid = false;
          }
          if(!testValidField(item.connectionDetails.region, true)){
            valid = false;
          }
          if(item.connectionDetails.credentials){            
            // if(!testValidField(item.connectionDetails.credentials.logonType, true)){
            //   valid = false;
            // }
            // if(item.connectionDetails.credentials.logonType === "credentials"){            
              if(!testValidField(item.connectionDetails.credentials.accessKeyId, true)){
                valid = false;
              }           
              if(!testValidField(item.connectionDetails.credentials.secretAccessKey, true)){
                valid = false;
              }
            // }
          }
          break;
        case "GCP":
          if(!testValidField(item.connectionDetails.bucket, true)){
            valid = false;
          }
          if(item.connectionDetails.credentials){            
            // if(!testValidField(item.connectionDetails.credentials.logonType, true)){
            //   valid = false;
            // }
            // if(item.connectionDetails.credentials.logonType === "credentials"){            
              if(!testValidField(item.connectionDetails.credentials.accessKey, true)){
                valid = false;
              }    
            // }
          }
          break;
        case "SFTP":
          if(!testValidField(item.connectionDetails.host, true)){
            valid = false;
          }
          if(item.connectionDetails.credentials){            
            if(!testValidField(item.connectionDetails.credentials.logonType, true)){
              valid = false;
            }
            if(item.connectionDetails.credentials.logonType === "credentials"){            
              if(!testValidField(item.connectionDetails.credentials.user, true)){
                valid = false;
              }            
              if(!testValidField(item.connectionDetails.credentials.password, true)){
                valid = false;
              }    
            }
            if(item.connectionDetails.credentials.logonType === "ssh_key_pair"){            
              if(!testValidField(item.connectionDetails.credentials.user, true)){
                valid = false;
              }            
              if(!testValidField(item.connectionDetails.credentials.publicKey, true)){
                valid = false;
              }            
              if(!testValidField(item.connectionDetails.credentials.privateKey, true)){
                valid = false;
              }    
            }
          }
          break;
        default:
          break;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  const generateSSHKeys = () => {  
    setErrorMessage(null);  
    setSuccessMessage(null);
    DestinationsService.generateSSHKeys().then((response) => {
      // item.connectionDetails.credentials.publicKey
      let tempConnectionDetails = {...item.connectionDetails};
      tempConnectionDetails.credentials.publicKey = response.public_key;
      tempConnectionDetails.credentials.privateKey = response.private_key;
      let tempItem = {...item};
      tempItem.connectionDetails = tempConnectionDetails;
      setItem(tempItem);
    }).catch((error) => {
      setErrorMessage(formatError(error));
      setSuccessMessage(null);
    });
  };
  
  const test = () => {   
    setTestLoading(true); 
    DestinationsService.test(item).then(() => {
      setSuccessMessage("Your destination is valid.");
      setErrorMessage(null);
      setTestLoading(false);
    }).catch((error) => {
      setErrorMessage(formatError(error));
      setSuccessMessage(null);
      setTestLoading(false);
    });
  };

  
  const save = () => {    
    DestinationsService.create(item).then(() => {
      replaceOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      }, "form");
      onSuccess();
    }).catch((error) => {
      setErrorMessage(formatError(error));
      setSuccessMessage(null);
    });
  };


  useEffect(() => {
    switch(item.connectionDetails.destinationType){
      case "AWS":
        DestinationsService.listRegions(item.connectionDetails.destinationType).then((response) => {
          setRegions(response);
        }).catch((error) => {
          setErrorMessage(formatError(error));
          setSuccessMessage(null);
        });
        break;
      case "Cloudflare":
        DestinationsService.listRegions(item.connectionDetails.destinationType).then((response) => {
          setRegions(response);
        }).catch((error) => {
          setErrorMessage(formatError(error));
          setSuccessMessage(null);
        });
        break;
      default:
        break;
    }
  }, [item.connectionDetails.destinationType]);

  return (
    <OzContainer>
      
      <HealthyWrapper>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Add destination"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("form")}>Back</OzButton>}
          footerRight={
            <>
              <PromptedButton 
                buttonVariant="silvertree" 
                buttonText={"Test"} 
                prompt={"TEST-DESTINATION"}
                onConfirm={() => test()} 
              /> 
              &nbsp;&nbsp;
              <OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>
            </>
          }
        >
          <OzRow>
            <OzCol widthmd="6">
              <OzInput
                label="Name"
                width="100%"
                value={item && item.name ? item.name : ""}
                highlighted={item && item.name !== ""}
                placeholder="Name"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.name = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="Description"
                width="100%"
                value={item && item.description ? item.description : ""}
                highlighted={item && item.description !== ""}
                placeholder="Description"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.description = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzSelect
                label="Type"
                onChange={(val) => {
                  let tempCredentials = {};
                  let tempConnectionDetails = {
                    includeProjectInRemotePath: item.connectionDetails && item.connectionDetails.includeProjectInRemotePath !== undefined ? item.connectionDetails.includeProjectInRemotePath : true
                  };
                  tempConnectionDetails.destinationType = val;
                  switch(val){
                    case "AWS":
                      tempConnectionDetails.credentials = tempCredentials;
                      break;
                    case "Azure":
                      tempCredentials.logonType = "credentials";
                      tempConnectionDetails.credentials = tempCredentials;
                      break;
                    case "Cloudflare":
                      tempCredentials.logonType = "credentials";
                      tempConnectionDetails.credentials = tempCredentials;
                      break;
                    case "GCP":
                      tempCredentials.logonType = "credentials";
                      tempConnectionDetails.credentials = tempCredentials;
                      break;
                    case "SFTP":
                      tempConnectionDetails.port = 22;
                      tempConnectionDetails.credentials = tempCredentials;
                      break;
                    default:
                      break;
                  }
                  let tempItem = {...item};
                  tempItem.connectionDetails = tempConnectionDetails;
                  setItem(tempItem);
                }}
                selected={item && item.connectionDetails && item.connectionDetails.destinationType ? item.connectionDetails.destinationType : null}
                highlighted={item && item.connectionDetails && item.connectionDetails.destinationType !== undefined}
                values={[{
                  name: "AWS",
                  value: "AWS"
                },{
                  name: "Azure",
                  value: "Azure"
                },{
                  name: "Cloudflare",
                  value: "Cloudflare"
                },{
                  name: "GCP",
                  value: "GCP"
                },{
                  name: "SFTP",
                  value: "SFTP"
                }]}
                width="100%"
                maxHeight={"200px"}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              {/* <OzCheckbox
                labelSpacing
                label="Active"
                selected={item && item.active ? item.active : null}
                highlighted={item && item.active !== null}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.active = val;
                  setItem(tempItem);
                }}
              />*/}
            </OzCol>   
            <OzCol widthmd="6">
              <OzInput
                label="Remote directory"
                width="100%"
                value={item && item.connectionDetails && item.connectionDetails.remoteDirectory ? item.connectionDetails.remoteDirectory : ""}
                highlighted={item && item.connectionDetails && item.connectionDetails.remoteDirectory !== "" && item.connectionDetails.remoteDirectory !== undefined}
                placeholder="Remote directory"
                onChange={(val) => {
                  let tempItem = {...item};
                  if(!tempItem.connectionDetails){
                    tempItem.connectionDetails = {};
                  }
                  tempItem.connectionDetails.remoteDirectory = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzCheckbox
                labelSpacing
                label="Include project in remote path"
                selected={item && item.connectionDetails && item.connectionDetails.includeProjectInRemotePath ? item.connectionDetails.includeProjectInRemotePath : null}
                highlighted={item && item.connectionDetails && item.connectionDetails.includeProjectInRemotePath !== undefined}
                onChange={(val) => {
                  let tempItem = {...item};
                  if(!tempItem.connectionDetails){
                    tempItem.connectionDetails = {};
                  }
                  tempItem.connectionDetails.includeProjectInRemotePath = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>   
            { item && item.connectionDetails && item.connectionDetails.destinationType && item.connectionDetails.destinationType === "AWS" ?    
              <>
                <OzCol widthmd="4">
                  <OzInput
                    label="Bucket"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.bucket ? item.connectionDetails.bucket : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.bucket !== "" && item.connectionDetails.bucket !== undefined}
                    placeholder="Bucket"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.bucket = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
                <OzCol widthmd="4">
                  <OzSelect
                    label="Region"
                    onChange={(val) => {
                      let tempConnectionDetails = {...item.connectionDetails};
                      if(!tempConnectionDetails){
                        tempConnectionDetails = {};
                      }
                      tempConnectionDetails.region = val;
                      let tempItem = {...item};
                      tempItem.connectionDetails = tempConnectionDetails;
                      setItem(tempItem);
                    }}
                    selected={item && item.connectionDetails && item.connectionDetails.region ? item.connectionDetails.region : null}
                    highlighted={item && item.connectionDetails && item.connectionDetails.region !== undefined}
                    values={regions.map((item) => ({
                      "name": item,
                      "value": item,
                    }))}
                    width="100%"
                    maxHeight={"200px"}
                    required
                  />
                </OzCol>
                <OzCol widthmd="4">
                  <OzSelect
                    label="Logon type"
                    onChange={(val) => {
                      let tempConnectionDetails = {...item.connectionDetails};
                      let tempCredentials = {};
                      if(!tempConnectionDetails){
                        tempConnectionDetails = {};
                      }
                      tempCredentials.logonType = val;
                      tempConnectionDetails.credentials = tempCredentials;
                      let tempItem = {...item};
                      tempItem.connectionDetails = tempConnectionDetails;
                      setItem(tempItem);
                    }}
                    selected={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType ? item.connectionDetails.credentials && item.connectionDetails.credentials.logonType : null}
                    highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType !== undefined}
                    values={[{
                      name: "credentials",
                      value: "credentials"
                    },{
                      name: "policy",
                      value: "policy"
                    }]}
                    width="100%"
                    maxHeight={"200px"}
                    required
                  />
                </OzCol>  
                { item.connectionDetails.credentials && item.connectionDetails.credentials.logonType && item.connectionDetails.credentials.logonType === "credentials" ? 
                  <>
                    <OzCol widthmd="6">
                      <OzInput
                        label="AWS access key id"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.awsAccessKeyId ? item.connectionDetails.credentials.awsAccessKeyId : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.awsAccessKeyId !== "" && item.connectionDetails.credentials.awsAccessKeyId !== undefined}
                        placeholder="AWS access key id"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.awsAccessKeyId = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="AWS secret access key"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.awsSecretAccessKey ? item.connectionDetails.credentials.awsSecretAccessKey : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.awsSecretAccessKey !== "" && item.connectionDetails.credentials.awsSecretAccessKey !== undefined}
                        placeholder="AWS secret access key"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.awsSecretAccessKey = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                  </>       
                : null}   
                { item.connectionDetails.credentials && item.connectionDetails.credentials.logonType && item.connectionDetails.credentials.logonType === "policy" ? 
                  <>
                    <OzCol widthmd="12">
                      Be sure to follow the instructions to properly set your bucket policy described <Link href="" onClick={(e) => { e.preventDefault(); window.open("https://docs.ozwol.com/docs/output-of-the-results#your-bucket-on-aws", '_blank'); }}>here</Link>.<br/>
                      The value of OZWOL_ID to use in the policy is: <OzCopyable>{process.env.REACT_APP_AWS_ACCOUNT_ID}</OzCopyable>
                    </OzCol>
                  </>       
                : null}  
              </>          
            : null}       

            { item && item.connectionDetails && item.connectionDetails.destinationType && item.connectionDetails.destinationType === "Azure" ?    
              <>
                <OzCol widthmd="4">
                  <OzInput
                    label="Storage account name"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.storageAccountName ? item.connectionDetails.storageAccountName : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.storageAccountName !== "" && item.connectionDetails.storageAccountName !== undefined}
                    placeholder="Storage account name"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.storageAccountName = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
                <OzCol widthmd="4">
                  <OzInput
                    label="Container"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.container ? item.connectionDetails.container : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.container !== "" && item.connectionDetails.container !== undefined}
                    placeholder="Container"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.container = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
                {/* <OzCol widthmd="4">
                  <OzSelect
                    label="Logon type"
                    onChange={(val) => {
                      let tempConnectionDetails = {...item.connectionDetails};
                      let tempCredentials = {};
                      if(!tempConnectionDetails){
                        tempConnectionDetails = {};
                      }
                      tempCredentials.logonType = val;
                      tempConnectionDetails.credentials = tempCredentials;
                      let tempItem = {...item};
                      tempItem.connectionDetails = tempConnectionDetails;
                      setItem(tempItem);
                    }}
                    selected={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType ? item.connectionDetails.credentials && item.connectionDetails.credentials.logonType : null}
                    highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType !== undefined}
                    values={[{
                      name: "credentials",
                      value: "credentials"
                    }]}
                    width="100%"
                    maxHeight={"200px"}
                    required
                  />
                </OzCol>   */}
                {/* { item.connectionDetails.credentials && item.connectionDetails.credentials.logonType && item.connectionDetails.credentials.logonType === "credentials" ? 
                  <> */}
                    <OzCol widthmd="4">
                      <OzTextarea
                        label="Access key"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.accessKey ? item.connectionDetails.credentials.accessKey : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.accessKey !== "" && item.connectionDetails.credentials.accessKey !== undefined}
                        placeholder="Access key"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.accessKey = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                  {/* </>       
                : null}    */}
              </>          
            : null}   
            
            { item && item.connectionDetails && item.connectionDetails.destinationType && item.connectionDetails.destinationType === "Cloudflare" ?    
              <>
                <OzCol widthmd="4">
                  <OzInput
                    label="Account id"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.accountId ? item.connectionDetails.accountId : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.accountId !== "" && item.connectionDetails.accountId !== undefined}
                    placeholder="Account id"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.accountId = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
                <OzCol widthmd="4">
                  <OzInput
                    label="Bucket"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.bucket ? item.connectionDetails.bucket : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.bucket !== "" && item.connectionDetails.bucket !== undefined}
                    placeholder="Bucket"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.bucket = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
                <OzCol widthmd="4">
                  <OzSelect
                    label="Region"
                    onChange={(val) => {
                      let tempConnectionDetails = {...item.connectionDetails};
                      if(!tempConnectionDetails){
                        tempConnectionDetails = {};
                      }
                      tempConnectionDetails.region = val;
                      let tempItem = {...item};
                      tempItem.connectionDetails = tempConnectionDetails;
                      setItem(tempItem);
                    }}
                    selected={item && item.connectionDetails && item.connectionDetails.region ? item.connectionDetails.region : null}
                    highlighted={item && item.connectionDetails && item.connectionDetails.region !== undefined}
                    values={regions.map((item) => ({
                      "name": item,
                      "value": item,
                    }))}
                    width="100%"
                    maxHeight={"200px"}
                    required
                  />
                </OzCol>
                {/* <OzCol widthmd="4">
                  <OzSelect
                    label="Logon type"
                    onChange={(val) => {
                      let tempConnectionDetails = {...item.connectionDetails};
                      let tempCredentials = {};
                      if(!tempConnectionDetails){
                        tempConnectionDetails = {};
                      }
                      tempCredentials.logonType = val;
                      tempConnectionDetails.credentials = tempCredentials;
                      let tempItem = {...item};
                      tempItem.connectionDetails = tempConnectionDetails;
                      setItem(tempItem);
                    }}
                    selected={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType ? item.connectionDetails.credentials && item.connectionDetails.credentials.logonType : null}
                    highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType !== undefined}
                    values={[{
                      name: "credentials",
                      value: "credentials"
                    }]}
                    width="100%"
                    maxHeight={"200px"}
                    required
                  />
                </OzCol>   */}
                {/* { item.connectionDetails.credentials && item.connectionDetails.credentials.logonType && item.connectionDetails.credentials.logonType === "credentials" ? 
                  <> */}
                    <OzCol widthmd="6">
                      <OzInput
                        label="Access key id"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.accessKeyId ? item.connectionDetails.credentials.accessKeyId : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.accessKeyId !== "" && item.connectionDetails.credentials.accessKeyId !== undefined}
                        placeholder="Access key id"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.accessKeyId = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Secret access key"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.secretAccessKey ? item.connectionDetails.credentials.secretAccessKey : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.secretAccessKey !== "" && item.connectionDetails.credentials.secretAccessKey !== undefined}
                        placeholder="Secret access key"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.secretAccessKey = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                  {/* </>       
                : null}    */}
              </>          
            : null} 
            
            { item && item.connectionDetails && item.connectionDetails.destinationType && item.connectionDetails.destinationType === "GCP" ?    
              <>
                <OzCol widthmd="6">
                  <OzInput
                    label="Bucket"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.bucket ? item.connectionDetails.bucket : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.bucket !== "" && item.connectionDetails.bucket !== undefined}
                    placeholder="Bucket"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.bucket = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
                {/* <OzCol widthmd="4">
                  <OzSelect
                    label="Logon type"
                    onChange={(val) => {
                      let tempConnectionDetails = {...item.connectionDetails};
                      let tempCredentials = {};
                      if(!tempConnectionDetails){
                        tempConnectionDetails = {};
                      }
                      tempCredentials.logonType = val;
                      tempConnectionDetails.credentials = tempCredentials;
                      let tempItem = {...item};
                      tempItem.connectionDetails = tempConnectionDetails;
                      setItem(tempItem);
                    }}
                    selected={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType ? item.connectionDetails.credentials && item.connectionDetails.credentials.logonType : null}
                    highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType !== undefined}
                    values={[{
                      name: "credentials",
                      value: "credentials"
                    }]}
                    width="100%"
                    maxHeight={"200px"}
                    required
                  />
                </OzCol>   */}
                {/* { item.connectionDetails.credentials && item.connectionDetails.credentials.logonType && item.connectionDetails.credentials.logonType === "credentials" ? 
                  <> */}
                    <OzCol widthmd="6">
                      <OzTextarea
                        label="Access key"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.accessKey ? item.connectionDetails.credentials.accessKey : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.accessKey !== "" && item.connectionDetails.credentials.accessKey !== undefined}
                        placeholder="Access key"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.accessKey = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                  {/* </>       
                : null}    */}
              </>          
            : null}   
            
            { item && item.connectionDetails && item.connectionDetails.destinationType && item.connectionDetails.destinationType === "SFTP" ?    
              <>
                <OzCol widthmd="4">
                  <OzInput
                    label="Host"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.host ? item.connectionDetails.host : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.host !== "" && item.connectionDetails.host !== undefined}
                    placeholder="Host"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.host = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
                <OzCol widthmd="4">
                  <OzInput
                    label="Port"
                    width="100%"
                    value={item && item.connectionDetails && item.connectionDetails.port ? item.connectionDetails.port : ""}
                    highlighted={item && item.connectionDetails && item.connectionDetails.port !== 22 && item.connectionDetails.port !== undefined}
                    placeholder="Port"
                    onChange={(val) => {
                      let tempItem = {...item};
                      if(!tempItem.connectionDetails){
                        tempItem.connectionDetails = {};
                      }
                      tempItem.connectionDetails.port = parseInt(val);
                      setItem(tempItem);
                    }}
                  />
                </OzCol>
                <OzCol widthmd="4">
                  <OzSelect
                    label="Logon type"
                    onChange={(val) => {
                      let tempConnectionDetails = {...item.connectionDetails};
                      let tempCredentials = {};
                      if(!tempConnectionDetails){
                        tempConnectionDetails = {};
                      }
                      tempCredentials.logonType = val;
                      tempConnectionDetails.credentials = tempCredentials;
                      let tempItem = {...item};
                      tempItem.connectionDetails = tempConnectionDetails;
                      setItem(tempItem);
                    }}
                    selected={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType ? item.connectionDetails.credentials && item.connectionDetails.credentials.logonType : null}
                    highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.logonType !== undefined}
                    values={[{
                      name: "credentials",
                      value: "credentials"
                    },{
                      name: "ssh_key_pair",
                      value: "ssh_key_pair"
                    }]}
                    width="100%"
                    maxHeight={"200px"}
                    required
                  />
                </OzCol>
                { item.connectionDetails.credentials && item.connectionDetails.credentials.logonType && item.connectionDetails.credentials.logonType === "credentials" ? 
                  <>
                    <OzCol widthmd="6">
                      <OzInput
                        label="User"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.user ? item.connectionDetails.credentials.user : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.user !== "" && item.connectionDetails.credentials.user !== undefined}
                        placeholder="User"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.user = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Password"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.password ? item.connectionDetails.credentials.password : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.password !== "" && item.connectionDetails.credentials.password !== undefined}
                        placeholder="Password"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.password = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                  </>       
                : null}
                { item.connectionDetails.credentials && item.connectionDetails.credentials.logonType && item.connectionDetails.credentials.logonType === "ssh_key_pair" ? 
                  <>
                    <OzCol widthmd="6">
                      <OzInput
                        label="User"
                        width="100%"
                        value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.user ? item.connectionDetails.credentials.user : ""}
                        highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.user !== ""}
                        placeholder="User"
                        onChange={(val) => {
                          let tempConnectionDetails = {...item.connectionDetails};
                          let tempCredentials = {};
                          if(!tempConnectionDetails){
                            tempConnectionDetails = {};
                          }else{
                            tempCredentials = {...tempConnectionDetails.credentials};
                          }
                          tempCredentials.user = val;
                          tempConnectionDetails.credentials = tempCredentials;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzSelect
                        label="Keys to use into Pipelines"
                        onChange={(val) => {
                          setIsGenerateKeys(val);
                          let tempConnectionDetails = {...item.connectionDetails};
                          delete tempConnectionDetails.credentials.publicKey;
                          delete tempConnectionDetails.credentials.privateKey;
                          let tempItem = {...item};
                          tempItem.connectionDetails = tempConnectionDetails;
                          setItem(tempItem);
                        }}
                        selected={isGenerateKeys}
                        values={[{
                          name: "Use Own Keys",
                          value: false
                        },{
                          name: "Generate Keys",
                          value: true
                        }]}
                        width="100%"
                        maxHeight={"200px"}
                        required
                      />
                    </OzCol>
                    {isGenerateKeys === false ? 
                      <>
                        <OzCol widthmd="6">
                          <OzTextarea
                            label="Public key"
                            width="100%"
                            value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.publicKey ? item.connectionDetails.credentials.publicKey : ""}
                            highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.publicKey !== ""}
                            placeholder="Public key"
                            copyable
                            onChange={(val) => {
                              let tempConnectionDetails = {...item.connectionDetails};
                              let tempCredentials = {};
                              if(!tempConnectionDetails){
                                tempConnectionDetails = {};
                              }else{
                                tempCredentials = {...tempConnectionDetails.credentials};
                              }
                              tempCredentials.publicKey = val;
                              tempConnectionDetails.credentials = tempCredentials;
                              let tempItem = {...item};
                              tempItem.connectionDetails = tempConnectionDetails;
                              setItem(tempItem);
                            }}
                            required
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzTextarea
                            label="Private key"
                            width="100%"
                            value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.privateKey ? item.connectionDetails.credentials.privateKey : ""}
                            highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.privateKey !== undefined}
                            placeholder={"Private key"}       
                            onChange={(val) => {
                              let tempConnectionDetails = {...item.connectionDetails};
                              let tempCredentials = {};
                              if(!tempConnectionDetails){
                                tempConnectionDetails = {};
                              }else{
                                tempCredentials = {...tempConnectionDetails.credentials};
                              }
                              tempCredentials.privateKey = val;
                              tempConnectionDetails.credentials = tempCredentials;
                              let tempItem = {...item};
                              tempItem.connectionDetails = tempConnectionDetails;
                              setItem(tempItem);
                            }}
                            required
                          />
                        </OzCol>
                      </>
                    :
                      <>
                        <OzCol widthmd="6">
                          <div style={{"height": "43px"}} />
                          <OzButton width="100%" onClick={() => generateSSHKeys()}>Generate</OzButton>
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzTextarea
                            label="Public key"
                            width="100%"
                            value={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.publicKey ? item.connectionDetails.credentials.publicKey : ""}
                            highlighted={item && item.connectionDetails && item.connectionDetails.credentials && item.connectionDetails.credentials.publicKey !== ""}
                            placeholder="Public key"
                            copyable
                            disabled
                            required
                          />
                        </OzCol>
                        <OzCol widthmd="12">
                          Copy this public key to ~/.ssh/authorized_keys on the remote host.
                        </OzCol>
                      </>
                    }
                  </>    
                : null}
              </>          
            : null}   
          </OzRow>
          <SuccessMessage>
            {successMessage}
          </SuccessMessage>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>

          {testLoading ? 
            <OzLoading />
          : null }
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default DestinationsModalNew;
