import { S3Client, HeadObjectCommand, ListObjectsV2Command, GetObjectCommand, PutObjectCommand, CopyObjectCommand, DeleteObjectCommand, DeleteObjectsCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
// import {queryParams} from '../helpers';

class WorkspaceService {

  constructor() { 
    this.bucket = null;
    this.client = null;
  }

  init(config) {
    this.remoteDirectory = config.projectUuid;
    this.bucket = process.env.REACT_APP_DEFAULT_BUCKET_NAME;
    this.client = new S3Client({
      region: 'eu-west-1',
      credentials: {
        accessKeyId: config.awsAccessKeyId,
        secretAccessKey: config.awsSecretAccessKey,
      },
    });
  }

  list(previousResponse = [], nextContinuationToken = null) {
    return new Promise((resolve, reject) => {
      const cmd = new ListObjectsV2Command({
        Bucket: this.bucket,
        Prefix: this.remoteDirectory+'/',
        ContinuationToken: nextContinuationToken
      })      
      this.client.send(cmd).then(response => {
        if(response.IsTruncated){
          this.list(previousResponse.concat(response.Contents), response.NextContinuationToken).then(response2 => {
            resolve(response2);
          });
        }else{
          resolve(previousResponse.concat(response.Contents));
        }        
      }).catch(error => {
        reject(error);
      });
    });
  }

  upload(path, name, content) {
    return new Promise((resolve, reject) => {
      try {
        let realUser = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
        const parallelUploads3 = new Upload({
          client: this.client,
          params: { 
            Bucket: this.bucket,
            Key: path+name,
            Body: content,
            Tagging: (realUser.groups.includes("OzwolCoworker") ? "coworkerUuid="+realUser.ParsedJwtToken.sub : null)
          }
        });
        let status = localStorage.getItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
        if(!status){
          status = {};
        }else{
          status = JSON.parse(status);
        }
        status[path+name]={};
        status[path+name]["percent"] = 0;
        status[path+name]["lastUpdate"] = Date.now();
        localStorage.setItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(status));

        
      
        parallelUploads3.on("httpUploadProgress", (progress) => {
          let status = localStorage.getItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
          if(!status){
            status = {};
          }else{
            status = JSON.parse(status);
          }
          status[path+name]["percent"] = parseInt(progress.loaded * 100 / progress.total);
          status[path+name]["lastUpdate"] = Date.now();
          localStorage.setItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(status));


          if(status[path+name]["percent"] === 100){
            setTimeout(() => {
              let status = localStorage.getItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
              if(!status){
                status = {};
              }else{
                status = JSON.parse(status);
              }
              delete status[path+name];

              localStorage.setItem("ozwolConsoleLoading"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(status));

            }, 250);
          }
          
        });
      
        resolve(parallelUploads3.done());
      } catch (e) {
        console.log(e);
      }
    });
  }

  load(path, name, content) {
    return new Promise((resolve, reject) => {
      const cmd = new PutObjectCommand({
        Bucket: this.bucket,
        Key: path+name,
        Body: content,
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  get(filePath) {
    return new Promise((resolve, reject) => {
      const cmd = new GetObjectCommand({
        Bucket: this.bucket,
        Key: filePath
      })      
      this.client.send(cmd).then(response => {
        resolve(response.Body.transformToString("base64")); //"base64"
      }).catch(error => {
        reject(error);
      });
    });
  }


  retry(path) {
    return new Promise((resolve, reject) => {
      const cmd = new HeadObjectCommand({
        Bucket: this.bucket,
        Key: path
      })      
      this.client.send(cmd).then(response1 => {
        const cmd = new CopyObjectCommand({
          Bucket: this.bucket,
          CopySource: "/"+this.bucket+"/"+path,
          Key: path,
          MetadataDirective: 'REPLACE'
        })      
        this.client.send(cmd).then(response2 => {
          resolve(response2);
        }).catch(error => {
          reject(error);
        });
      }).catch(error => {
        resolve(false);
      });
    });
      
  }

  delete(path) {
    return new Promise((resolve, reject) => {
      const cmd = new DeleteObjectCommand({
        Bucket: this.bucket,
        Key: path
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }

  deleteList(paths) {
    return new Promise((resolve, reject) => {
      if(paths.length > 0){
        const cmd = new DeleteObjectsCommand({
          Bucket: this.bucket,
          Delete: {
            Objects: paths.slice(0,1000).map(path => {return {Key: path}})
          }
        })      
        this.client.send(cmd).then(response => {
          if(paths.slice(1000).length > 0){
            this.deleteList(paths.slice(1000)).then(response2 => {
              resolve(response2);
            });
          }else{
            resolve(response);
          }
        }).catch(error => {
          reject(error);
        });
      }else{
        resolve();
      }
    });
  }

  checkFolderExists(path, name) {
    return new Promise((resolve, reject) => {
      const cmd = new HeadObjectCommand({
        Bucket: this.bucket,
        Key: path+name+"/"
      })      
      this.client.send(cmd).then(response => {
        resolve(true);
      }).catch(error => {
        resolve(false);
      });
    });
  }

  createFolder(path, name) {
    return new Promise((resolve, reject) => {
      this.checkFolderExists(path, name).then(response => {
        if(!response){
          const cmd = new PutObjectCommand({
            Bucket: this.bucket,
            Key: path+name+"/"
          })      
          this.client.send(cmd).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
        }
      }).catch(error => {
        reject(error);
      })
    });
  }

  deleteFolder(path) {
    return new Promise((resolve, reject) => {
      // const cmd = new ListObjectsV2Command({
      //   Bucket: this.bucket,
      //   Prefix: this.remoteDirectory+'/'+path
      // })      
      // this.client.send(cmd).then(response => {
      //   console.log(response);
      //   for (var i = 0; i < response.Contents.length; i++) { 
      //     console.log(response.Contents[i]);
      //   }        
      //   // resolve(response);
      // }).catch(error => {
      //   reject(error);
      // });


      const cmd = new DeleteObjectCommand({
        Bucket: this.bucket,
        Key: path
      })      
      this.client.send(cmd).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service =  new WorkspaceService();
export default service;