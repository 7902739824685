//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import styled from 'styled-components';

import {BITTERSWEET, OzTooltip, OzContainer, OzButton, OzTable, OzCol, OzRow, OzSelect, OzInput, OzCard, OzProjectTag, OzCheckbox} from "@ozwol/webui";

import CoworkersService from '../../services/CoworkersService';

import ModalSuccess from '../../components/ModalSuccess';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, testValidField, clearObject} from '../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2),
  & table tr td:nth-child(3),
  & table tr td:nth-child(5){
    word-break: break-all;
    white-space: unset;
  }
`;
const ProjectsWrapper = styled.div`
  width: 100%;
  max-height: ${props => props.show ? "300px" : "0px"};
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s ease-in-out;

`;

const MembersModalNew = ({projects, onSuccess}) => {
  const {replaceOverlay, removeOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState(null);
  const [listRoles, setListRoles] = useState([]); //null

  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [disabled, setDisabled] = useState(true);


  useEffect(() => {
    CoworkersService.listRoles().then((response) => {
      setListRoles(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, []); 

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(item.givenName, true)){
        valid = false;
      }
      if(!testValidField(item.familyName, true)){
        valid = false;
      }
      if(!testValidField(item.email, true)){
        valid = false;
      }
      if(!testValidField(item.role, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  const save = () => {
    let salvageableItem = {...item};
    CoworkersService.create(clearObject(salvageableItem, ["givenName","familyName","email", "active", "locale", "role","permissions"])).then((response) => {
      replaceOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      }, "form");
      onSuccess();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  };


  return (
    <OzContainer size="medium">
      
      <HealthyWrapper error={fetchError} loading={!listRoles}>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"New member"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("form")}>Back</OzButton>}
          footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
        >
          <OzRow>
            <OzCol widthmd="4">
              <OzInput
                label="Given name"
                width="100%"
                value={item && item.givenName ? item.givenName : ""}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.givenName = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="4">
              <OzInput
                label="Family name"
                width="100%"
                value={item && item.familyName ? item.familyName : ""}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.familyName = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="4">
              <OzSelect
                label="Role"
                maxHeight={"300px"}
                selected={item && item.role ? item.role : null}
                values={listRoles.map((role) => ({
                  "name": role.role,
                  "value": role.role
                }))}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.role = val;
                  if(!tempItem.permissions){
                    tempItem.permissions = {};
                  }  
                  tempItem.permissions.activity = [];//listRoles.filter(item => item.role === val)[0].activity;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="8">
              <OzInput
                label="Email"
                width="100%"
                value={item && item.email ? item.email : ""}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.email = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="4">
              <OzCheckbox
                labelSpacing={true}
                toggle={true}
                label={<>All projects <OzTooltip>Enables access to all current and future projects of the account. To limit access to specific projects, select the projects from the list below</OzTooltip></>}
                selected={item && item.permissions && item.permissions.project && item.permissions.project.length > 0 && item.permissions.project[0] === "*" ? true : null}
                onChange={(val) => {
                  let tempItem = {...item};       
                  if(!tempItem.permissions){
                    tempItem.permissions = {};
                  }  
                  if(val){
                    tempItem.permissions.project = ["*"];
                  }else{
                    tempItem.permissions.project = [];
                  }
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <br/><br/><br/>
            <OzCol widthmd="12">
              <ProjectsWrapper show={!item || !item.permissions || !item.permissions.project || (item.permissions.project.length === 0 || (item.permissions.project.length > 0 && item.permissions.project[0] !== "*"))}>
                <TableWrapper>
                  <OzTable
                    selectable={true}
                    selected={item && item.permissions && item.permissions.project && item.permissions.project.length > 0 && item.permissions.project[0] !== "*" ? item.permissions.project : []}
                    onSelect={(ids) => {
                      let tempItem = {...item};     
                      if(!tempItem.permissions){
                        tempItem.permissions = {};
                      }  
                      if(!tempItem.permissions.project || (item && item.permissions && item.permissions.project && item.permissions.project.length > 0 && item.permissions.project[0] === "*")){
                        tempItem.permissions.project = [];
                      }
                      
                      ids.forEach(id => {
                        if(!tempItem.permissions.project.includes(id)){
                          tempItem.permissions.project.push(id);
                        }else{
                          tempItem.permissions.project = tempItem.permissions.project.filter(e => e !== id);
                        }                    
                      });
                      setItem(tempItem);
                    }}
                    values={
                      projects.map(item => ({
                        id: item.uuid,
                        style: !item.active ? {
                          background: BITTERSWEET+"33",
                          backgroundHover: BITTERSWEET+"55"
                        } : {},
                        values: [
                          <OzProjectTag
                            direction="horizontal"
                            key={item.uuid}
                            name={item.name}
                            uuid={item.uuid}
                            color={item.color}
                          />
                        ]
                      }))
                    }
                  />
                </TableWrapper>
              </ProjectsWrapper>
            </OzCol>
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          <br/>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default MembersModalNew;
