import React, {useContext, useState, useEffect, useCallback}  from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {CognitoContext} from '../../contexts/CognitoContext';

import { SILVERTREE, MUSTARD, BITTERSWEET, SPANISHGREY, OzIcon, OzSelect, OzUserTag } from '@ozwol/webui';

import ImpersonificationService from './../../services/ImpersonificationService';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 40px;
  background-color: ${SILVERTREE};
  color: #ffffff;

  padding: 0px 20px;

  & * {
    box-shadow: none;
  }
`;

const Side = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

const AccountName = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px;
`;

const ClearWrapper = styled.div`
  cursor: pointer;
`;
const Clear = styled.div`
  width: 18px;
  margin-left: 10px;
`;
const Uuid = styled.div`
  font-size: 10px;
  margin-right: 10px;
`;

const Row = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  & > :first-child{
    flex-grow: 1;
    flex-shrink: 1;
  }
  & > :last-child{
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${props => props.color};
  margin: 0 auto;
`;

const ImpersonificationBar = () => {
  const {cognito} = useContext(CognitoContext);
  let navigate = useNavigate();

  const [impersonificationActive, setImpersonificationActive] = useState(null);
  const [users, setUsers] = useState(null);


  useEffect(() => {
    ImpersonificationService.list({
      "active": true
    }).then((response) => {
      setUsers(response.result);
      setImpersonificationActive(response.result.length > 0);
    }).catch((error) => {
      setUsers(null);
      setImpersonificationActive(false);
    });
  }, [setUsers]);


  const getImpersonificationList = (value) => {
    if((value && value.length > 2) || value === ""){
      ImpersonificationService.list({
        "search": value,
        "active": true
      }).then((response) => {
        setUsers(response.result);
      }).catch((error) => {
        setUsers(null);
      });
    }
  }

  const setImpersonification = useCallback((value) => {
    if(value){
      localStorage.setItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), value);
      navigate("/refresh");
    }
  }, [navigate]);

  const removeImpersonification = useCallback(() => {
    localStorage.removeItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)));
    navigate("/refresh");
  }, [navigate]);

  if(impersonificationActive){
    let realUser = JSON.parse(localStorage.getItem("ozwolConsoleUser"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))));
    return (
      <Wrapper>
        <Side>
          <OzIcon name={"impersonification"} color={"#ffffff"} size="18px" />
          <AccountName>
            {realUser.ParsedJwtToken.given_name + " " + realUser.ParsedJwtToken.family_name}
          </AccountName>
        </Side>
        <Side>
          <Uuid>
            {localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))}
          </Uuid>          
          <OzSelect
            selected={localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))}
            placeholder={"Select user"}
            maxHeight={"400px"}
            width={"460px"}
            values={users && users.length > 0 ? users.filter((item) => item.uuid !== cognito.ParsedJwtToken.sub).map((item) => ({
              "name": <Row>
                <OzUserTag
                  direction="horizontal"
                  name={item.givenName + " " + item.familyName + (" ")}
                  company={item.company}
                />
                <Dot color={
                  item.status === "OPERATIVE" && item.accountToBeClosed === false ? SILVERTREE : 
                  item.status === "OPERATIVE" && item.accountToBeClosed === true ? MUSTARD : 
                  item.status === "SUSPENDED" ? BITTERSWEET : 
                  item.status === "CLOSED" ? SPANISHGREY : 
                  null
                } />
              </Row>,
              "value": item.uuid
            })) : []}
            onChange={(val) => setImpersonification(val)}
            searchable={true}
            onSearch={(val) => getImpersonificationList(val)}
          />

          <ClearWrapper>
            {localStorage.getItem("ozwolConsoleImpersonification"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ?
              <Clear onClick={() => removeImpersonification()}>
                <OzIcon name={"remove"} color={"#ffffff"} size="18px" />
              </Clear>
            : null}
          </ClearWrapper>
        </Side>
      </Wrapper>
    );
  }else{
    return null;
  }
}

export default ImpersonificationBar;
