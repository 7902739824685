//TODO pulizia file
import React, { useState, useContext, useEffect, useCallback} from 'react';
import styled from 'styled-components';

import {OverlayContext} from '../../contexts/OverlayContext';

import {BORDER, OzContainer, OzButton, OzSelect, OzTable, OzInput, OzCheckbox, OzCheck, OzTooltip, OzCard} from "@ozwol/webui";

import HotfoldersService from '../../services/HotfoldersService';
import ScriptsService from '../../services/ScriptsService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import HealthyWrapper from '../../components/HealthyWrapper';
import ModalSuccess from '../../components/ModalSuccess';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, clearObject} from '../../helpers'; //testValidField

const OUTPUT_FORMAT_STRUCTURE = [
  "type",
  "limitFileSize",
  "quality",
  "dpi",
  "progressive",
  "iccProfile"
]

const Block = styled.div`
  // border-bottom: 1px solid ${BORDER};
  padding-bottom: 30px;
  margin-bottom: 30px;
  
  & table td:nth-child(1){
    width: 70px;
  }
  & table td:nth-child(2){
    width: 300px;
  }
`;

const ParamGroupTitle = styled.div`
  font-weight: bold;
`;

const ParamGroupFieldInput = styled.div`
  // margin-top: -20px;
  width: 100%;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: row;
`;
const ParamGroupFieldNullable = styled.div`
  padding-top: 50px;
  padding-right: 5px;
`;

const InsiderHotfoldersModalBulkEdit = ({listHotfolders}) => {
  const {addOverlay, removeOverlay} = useContext(OverlayContext);  
  
  const [item, setItem] = useState(null);
  const [selectedFieldsScriptValues, setSelectedFieldsScriptValues] = useState([]);
  const [selectedFieldsOutputFormat, setSelectedFieldsOutputFormat] = useState([]);

  const [script, setScript] = useState(null);
  const [listActions, setListActions] = useState(null);
  // const [listFields, setListFields] = useState(null);

  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const getItem = useCallback(() => {
    Promise.all(listHotfolders.map(hotfolderUuid => HotfoldersService.adminListActions({"hotfolderUuid": hotfolderUuid}))).then(results => {
      let tempListActions = [];
      results.forEach((result) => tempListActions.push(result.result[0]))
      setListActions(tempListActions);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
    
    HotfoldersService.adminListActions({"hotfolderUuid": listHotfolders[0]}).then((response) => {
      ScriptsService.get(response.result[0].scriptUuid).then((response2) => {
        setScript(response2);
        let tempItem = {...response};
        let tempScriptValues = {...response2.defaults};
        tempItem.scriptValues = {...tempScriptValues, ...tempItem.scriptValues};
        Object.keys(tempItem.scriptValues).map(key => tempItem.scriptValues[key] = null);
        setItem(tempItem);
      });

      // setListFields(response.result[0]);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [listHotfolders]);



  useEffect(() => {
    getItem();
  }, [listHotfolders, getItem]);

  const save = () => {
    setErrorMessage();
    Promise.all(listActions.map(action => {
      let tempAction = {...action};
			selectedFieldsScriptValues.forEach(field => tempAction.scriptValues[field] = item.scriptValues[field]);
      selectedFieldsOutputFormat.forEach(field => tempAction.outputFormat[field] = item.outputFormat[field]);
      return HotfoldersService.adminUpdateActions(action.uuid, clearObject(tempAction, [
      "scriptValues",
      "outputFormat"
      ]))
    })).then((responses) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      // setItem(response);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });

  };

  const updateField = (name, value, nullable = false) => {
    let tempItem = {...item};
    let values = {...tempItem.scriptValues};
    if(nullable){
      values[name] = value;
    }else{
      values[name] = value !== null ? value : "";
    }
    tempItem.scriptValues = values;
    setItem(tempItem);
  }

  return (
    <OzContainer>
      
      <HealthyWrapper error={fetchError}>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Bulk edit"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("form")}>Back</OzButton>}
          footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"}>Save</OzButton>}
        >
          {script && script.params && item.scriptValues ?
            <>
              {[...new Set(Object.keys(script.params).map((key)=>script.params[key].group))].map((kGroup, iGroup) =>
                <Block key={iGroup}>
                  
                    <ParamGroupTitle>{kGroup ? kGroup : "No group"}</ParamGroupTitle><br/>
                    <OzTable 
                      checkable={true}             
                      columns={[
                        "Edit",
                        "Field",
                        "Value"
                      ]}
                      values={Object.keys(script.params).filter((key)=>script.params[key].group === kGroup).map((kParam, iParam) => ({values: [
                        <OzCheckbox
                          onChange={() => {
                            let tempSelectedFieldsScriptValues = [...selectedFieldsScriptValues]
                            if(!tempSelectedFieldsScriptValues.includes(kParam)){
                              tempSelectedFieldsScriptValues.push(kParam);
                            }else{
                              tempSelectedFieldsScriptValues = tempSelectedFieldsScriptValues.filter(e => e !== kParam);
                            }                    
                            setSelectedFieldsScriptValues(tempSelectedFieldsScriptValues);
                          }}
                          selected={selectedFieldsScriptValues.includes(kParam)}
                        />,
                        kParam,
                        <ParamGroupFieldInput>
                          {script.params[kParam].description[0] === "§" ?
                            <ParamGroupFieldNullable>
                              <OzCheck
                                onChange={(val) => {
                                  updateField(kParam, val ? "" : null, true);
                                  // console.log(val);
                                }}
                                selected={item.scriptValues[kParam] !== null}
                                width="100%"
                                disabled={!selectedFieldsScriptValues.includes(kParam)}
                              />
                            </ParamGroupFieldNullable>
                          : null }
                          {script.params[kParam].type === "string" ?
                            (script.params[kParam].admittedValues && script.params[kParam].admittedValues.length > 0 ?
                              <OzSelect
                                label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                                onChange={(val) => updateField(kParam, val)}
                                selected={item.scriptValues[kParam]}
                                values={script.params[kParam].admittedValues.length > 0 ? script.params[kParam].admittedValues.map(value => ({"name": value, "value": value})) : []}
                                width="100%"
                                maxHeight={"200px"}
                                required={script.params[kParam].mandatory}
                                disabled={!selectedFieldsScriptValues.includes(kParam)}
                              />
                            :
                              <OzInput
                                label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                                onChange={(val) => updateField(kParam, val)}
                                value={item.scriptValues[kParam] !== null ? item.scriptValues[kParam] : ""}
                                width="100%"
                                required={script.params[kParam].mandatory}
																disabled={!selectedFieldsScriptValues.includes(kParam)}
                              />
                            )
                          : script.params[kParam].type === "number" ? //TODO forza numero
                            <OzInput
                              label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                              onChange={(val) => updateField(kParam, val ? parseFloat(val) : "")}
                              value={item.scriptValues[kParam] !== null ? item.scriptValues[kParam] : ""}
                              width="100%"
                              required={script.params[kParam].mandatory}
                              disabled={!selectedFieldsScriptValues.includes(kParam)}
                            />
                          : script.params[kParam].type === "boolean" ?
                            <OzCheckbox
                              label={<><OzTooltip>{script.params[kParam].description.replaceAll("§","")}</OzTooltip> {kParam.replaceAll("_"," ")}</>}
                              onChange={(val) => updateField(kParam, (val))}
                              selected={item.scriptValues[kParam] ? item.scriptValues[kParam] : 0}
                              required={script.params[kParam].mandatory}
                              disabled={!selectedFieldsScriptValues.includes(kParam)}
                            />
                          :
                            null //"MISSING TYPE: " + script.params[kParam].type
                          }
                        </ParamGroupFieldInput>
                      ]}))}
                    />
                </Block>
              )}

							<Block>
                <ParamGroupTitle>Output format</ParamGroupTitle>
                <OzTable 
                  checkable={true}             
                  columns={[
                    "Edit",
                    "Field",
                    "Value"
                  ]}
                  values={OUTPUT_FORMAT_STRUCTURE.map((kOutputFormat) => ({values: [
                    <OzCheckbox
                      onChange={() => {
                        let tempSelectedFieldsOutputFormat = [...selectedFieldsOutputFormat]
                        if(!tempSelectedFieldsOutputFormat.includes(kOutputFormat)){
                          tempSelectedFieldsOutputFormat.push(kOutputFormat);
                        }else{
                          tempSelectedFieldsOutputFormat = tempSelectedFieldsOutputFormat.filter(e => e !== kOutputFormat);
                        }                    
                        setSelectedFieldsOutputFormat(tempSelectedFieldsOutputFormat);
                      }}
                      selected={selectedFieldsOutputFormat.includes(kOutputFormat)}
                    />,
                    kOutputFormat,
                    kOutputFormat === "type" ?
                      <OzSelect
                        label="Type"
                        onChange={(val) => {
                          let temp = {...item.outputFormat};
                          if(!temp){
                            temp = {};
                          }
                          temp.type = val;
                          switch(val.toUpperCase()){
                            case "JPG":
                            case "JPEG":
                              break;
                            case "PNG":
                              temp.quality = 0;
                              temp.progressive = false;
                              break;
                            case "WEBP":
                              temp.progressive = false;
                              break;
                            case "PSD":
                              temp.limitFileSize = 0;
                              temp.quality = 0;
                              temp.progressive = false;
                              break;
                            default:
                              break;
                          }
                          let tempItem = {...item};
                          tempItem.outputFormat = temp;
                          setItem(tempItem);
                        }}
                        selected={item.outputFormat && item.outputFormat.type ? (item.outputFormat.type.toUpperCase() === "JPEG" ? "JPG" : item.outputFormat.type.toUpperCase()) : null}
                        values={[{
                          name: "PNG",
                          value: "PNG"
                        },{
                          name: "JPG",
                          value: "JPG"
                        },{
                          name: "WEBP",
                          value: "WEBP"
                        },{
                          name: "PSD",
                          value: "PSD"
                        }]}
                        width="100%"
                        maxHeight={"200px"}
                        required
                        disabled={!selectedFieldsOutputFormat.includes(kOutputFormat)}
                      />
                    : kOutputFormat === "limitFileSize" ?
                      (item.outputFormat && item.outputFormat.type && item.outputFormat.type.toUpperCase() !== "PSD" ?
                        <OzInput
                          label="Limit file size (byte)"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            temp.limitFileSize = parseInt(val);
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && Number.isInteger(item.outputFormat.limitFileSize) ? item.outputFormat.limitFileSize : ""}
                          width="100%"
                          disabled={!selectedFieldsOutputFormat.includes(kOutputFormat)}
                        />
                      : null)
                    : kOutputFormat === "quality" ?
                      (item.outputFormat && item.outputFormat.type && item.outputFormat.type.toUpperCase() !== "PNG" && item.outputFormat.type.toUpperCase() !== "PSD" ?
                        <OzInput
                          label="Quality"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            temp.quality = parseInt(val);
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && Number.isInteger(item.outputFormat.quality) ? item.outputFormat.quality : ""}
                          width="100%"
                          disabled={!selectedFieldsOutputFormat.includes(kOutputFormat)}
                        />
                      : null)
                    : kOutputFormat === "dpi" ?
                      (item.outputFormat && item.outputFormat.type ?
                        <OzInput
                          label="DPI"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            temp.dpi = parseInt(val);
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          value={item.outputFormat && Number.isInteger(item.outputFormat.dpi) ? item.outputFormat.dpi : ""}
                          width="100%"
													disabled={!selectedFieldsOutputFormat.includes(kOutputFormat)}
													/>
												: null)
											: kOutputFormat === "progressive" ?
												(item.outputFormat && item.outputFormat.type && (item.outputFormat.type.toUpperCase() === "JPG" || item.outputFormat.type.toUpperCase() === "JPEG") ?
													<OzCheckbox
														label={"Progressive"}
														onChange={(val) => {
															let temp = {...item.outputFormat};
															temp.progressive = val;
															let tempItem = {...item};
															tempItem.outputFormat = temp;
															setItem(tempItem);
														}}
														selected={item.outputFormat && item.outputFormat.progressive ? item.outputFormat.progressive : false}
														disabled={!selectedFieldsOutputFormat.includes(kOutputFormat)}
													/>
												: null)
											: kOutputFormat === "iccProfile" ?
												(item.outputFormat && item.outputFormat.type ?


                        <OzSelect
                          label="ICC profile"
                          onChange={(val) => {
                            let temp = {...item.outputFormat};
                            temp.iccProfile = val;
                            let tempItem = {...item};
                            tempItem.outputFormat = temp;
                            setItem(tempItem);
                          }}
                          selected={item.outputFormat && item.outputFormat.iccProfile ? item.outputFormat.iccProfile : "keep"}
                          values={[{
                            name: "Keep",
                            value: "keep"
                          },{
                            name: "None",
                            value: "none"
                          },{
                            name: "SRGB",
                            value: "srgb"
                          },{
                            name: "AdobeRGB1998",
                            value: "AdobeRGB1998"
                          }]}
                          width="100%"
                          maxHeight={"200px"}
                          required
													disabled={!selectedFieldsOutputFormat.includes(kOutputFormat)}
                        />
                      : null)
                    : null
                  ]}))}
                />
              {/*}
                <OzRow>
                  <OzCol widthmd="12">
                  </OzCol>
                  <OzCol widthmd="12">
                    
                  </OzCol>

                </OzRow>
                <OzRow>
                  <OzCol widthmd="12">
                    <br/><br/>
                    <ParamGroupTitle>Destination format metadata</ParamGroupTitle>
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzCheckbox
                      label={"Keep image metadata"}
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        temp.imageMetadataKeep = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      selected={item.outputFormat && item.outputFormat.imageMetadataKeep ? item.outputFormat.imageMetadataKeep : false}
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="DocumentTitle"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.DocumentTitle = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.DocumentTitle ? item.outputFormat.imageMetadata.DocumentTitle : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Author (comma separated)"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.Author = val.split(",");
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Author ? item.outputFormat.imageMetadata.Author.join(",") : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="AuthorTitle"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.AuthorTitle = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.AuthorTitle ? item.outputFormat.imageMetadata.AuthorTitle : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Description"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.Description = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Description ? item.outputFormat.imageMetadata.Description : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="DescriptionWriter"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.DescriptionWriter = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.DescriptionWriter ? item.outputFormat.imageMetadata.DescriptionWriter : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Keywords (comma separated)"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.Keywords = val.split(",");
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Keywords ? item.outputFormat.imageMetadata.Keywords.join(",") : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="CopyrightNotice"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.CopyrightNotice = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.CopyrightNotice ? item.outputFormat.imageMetadata.CopyrightNotice : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="City"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.City = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.City ? item.outputFormat.imageMetadata.City : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="State"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.State = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.State ? item.outputFormat.imageMetadata.State : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Country"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.Country = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Country ? item.outputFormat.imageMetadata.Country : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="CreditLine"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.CreditLine = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.CreditLine ? item.outputFormat.imageMetadata.CreditLine : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Source"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.Source = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Source ? item.outputFormat.imageMetadata.Source : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Headline"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.Headline = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Headline ? item.outputFormat.imageMetadata.Headline : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Instructions"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.Instructions = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Instructions ? item.outputFormat.imageMetadata.Instructions : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="TransmissionReference"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        temp.imageMetadata.TransmissionReference = val;
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.TransmissionReference ? item.outputFormat.imageMetadata.TransmissionReference : ""}
                      width="100%"
                    />
                  </OzCol>
                  <OzCol widthmd="12">
                    <OzInput
                      label="Urgency (1-8)"
                      onChange={(val) => {
                        let temp = {...item.outputFormat};
                        if(!temp.imageMetadata){
                          temp.imageMetadata = {};
                        }
                        if(val === "" || (parseInt(val) > 0 && parseInt(val) < 9)){
                          temp.imageMetadata.Urgency = parseInt(val);
                        }
                        let tempItem = {...item};
                        tempItem.outputFormat = temp;
                        setItem(tempItem);
                      }}
                      value={item.outputFormat && item.outputFormat.imageMetadata && item.outputFormat.imageMetadata.Urgency ? item.outputFormat.imageMetadata.Urgency : ""}
                      width="100%"
                    />
                  </OzCol>

                </OzRow> */}

              </Block>
            </>
          : null}

          {/* <OzRow>
            <OzCol widthmd="12">
              <OzSelect
                label="Project"
                width="100%"
                placeholder="Project"
                selected={item && item.projectUuid ? item.projectUuid : null}
                highlighted={item && item.projectUuid}
                values={listProjects.map((item) => ({
                  "name": <OzProjectTag
                      name={item.name}
                      color={item.color}
                      uuid={item.uuid}
                      direction={"horizontal"}
                    />,
                  "value": item.uuid,
                }))}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.projectUuid = val;
                  setItem(tempItem);               
                }}
                maxHeight={"200px"}
                searchable={true}
                search={searchProjects}
                onSearch={(val) => setSearchProjects(val)}
                required
              />
            </OzCol>
            <OzCol widthmd="12">
              <OzInput
                label="Path"
                preLabel={"HOTFOLDERS/"}
                width="100%"
                value={item && item.path ? item.path : ""}
                highlighted={item && item.path && item.path !== "INPUT/"}
                placeholder="Path"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.path = val.replaceAll(" ","_");
                  tempItem.description = val.replace("INPUT/","");
                  setItem(tempItem);
                }}
              />              
              <WarningMessage>{!item.path.startsWith("INPUT/") ? "Warning: without INPUT/ some regexes may not work correctly." : null }</WarningMessage>              
            </OzCol>
            <OzCol widthmd="12">
              <OzInput
                label="Description"
                width="100%"
                value={item && item.description ? item.description : ""}
                highlighted={item && item.description && item.description.length > 0}
                placeholder="Description"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.description = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            
          </OzRow> */}
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default InsiderHotfoldersModalBulkEdit;