import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Link} from 'react-router-dom';
import {OverlayContext} from './../../contexts/OverlayContext';
import styled from 'styled-components';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable, OzProjectTag, OzUserTag} from '@ozwol/webui';

import ProjectsService from '../../services/ProjectsService';
import UsersService from './../../services/UsersService';

import InsiderProjectsModalNew from './InsiderProjectsModalNew';

import Filters from '../../components/Filters';
import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import NoResult from '../../components/NoResult';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError} from './../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2){
    word-break: break-all;
    white-space: unset;
  }
`;

const InsiderProjectsList = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);

  const [page, setPage] = useState(0);
  const [list, setList] = useState(null);
  const [listUsers, setListUsers] = useState([]);
  const [filterSearchUsers, setFilterSearchUsers] = useState([]);

  const [fetchError, setFetchError] = useState(false);

  const DEFAULT_FILTERS = {
    "search": "",
    "active": true,
    // "assigned": null,
    "userUuid": null
  };
  const [filters, setFilters] = useState(
    localStorage.getItem("ozwolConsoleFilterInsiderProjects"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? 
      JSON.parse(localStorage.getItem("ozwolConsoleFilterInsiderProjects"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))))
    : 
      DEFAULT_FILTERS
    );
  const [filtersSearch, setFiltersSearch] = useState({
    "userUuid": ""
  });

  const changeFilters = useCallback((name, value) => {
    setPage(0);
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const searchFilters = useCallback((name, value) => {
    let tempFiltersSearch = {...filtersSearch};
    tempFiltersSearch[name] = value;
    setFiltersSearch(tempFiltersSearch);


    if(value.length > 2 || value.length === 0){
      switch(name){
        case "userUuid":
          UsersService.adminList({
            "search": value.length > 2 ? value : ""
          }).then((response) => {
            setFilterSearchUsers(response.result);
          });
          break;
        default:
          break;
      }
    }

  }, [filtersSearch]);

  const getList = useCallback(() => {
    ProjectsService.adminList({
      "search": filters.search.length > 2 ? filters.search : "",
      "active": filters.active,
      // "assigned": filters.assigned,
      "userUuid": filters.userUuid
    }).then((response) => {
      localStorage.setItem("ozwolConsoleFilterInsiderProjects"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(filters));
      setList(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [filters]); //page, filters

  useEffect(() => {
    getList();
  }, [getList, page, filters]); 

  

  useEffect(() => {
    UsersService.adminList().then((response) => {
      setFilterSearchUsers(response.result);
      setListUsers(response.result);
    });
  }, []);


  return (
    <OzContainer size="extra">
      <Meta title={"Projects - Insider"} />
      <PageHeader
        breadcrumbsIcon="business_center"
        breadcrumbsText={["Projects"]}
        refScrollerPage={refScrollerPage}
        buttons={[
          {
            "icon": "add",
            "text": "Add",
            "onClick": () => addOverlay({
              reference: "form",
              content: <InsiderProjectsModalNew />
            })
          }
        ]}
      />

      <OzRow>
        <OzCol>
          <HealthyWrapper error={fetchError} loading={!list}>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"Projects"}
                />
              }
              headerSeparator={true}
            >
              <Filters
                onChange={(...args) => changeFilters(...args)}
                onSearch={(...args) => searchFilters(...args)}
                fields={
                  [
                    {
                      "label": "Search",
                      "name": "search",
                      "size": 4,
                      "type": "text",
                      "placeholder": null,
                      "value": filters.search
                    },{
                      "label": "Status",
                      "name": "active",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.active,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },
                        {
                          "name": "Enabled",
                          "value": true
                        },{
                          "name": "Disabled",
                          "value": false
                        }
                      ]
                    },{
                    //   "label": "Assigned",
                    //   "name": "assigned",
                    //   "size": 2,
                    //   "type": "select",
                    //   "placeholder": null,
                    //   "value": filters.assigned,
                    //   "options": [
                    //     {
                    //       "name": <i>All</i>,
                    //       "value": null
                    //     },{
                    //       "name": "Yes",
                    //       "value": true
                    //     },{
                    //       "name": "No",
                    //       "value": false
                    //     }
                    //   ]
                    // },{
                      "label": "Owner",
                      "name": "userUuid",
                      "size": 6,
                      "type": "searchableselect",
                      "placeholder": null,
                      "value": filters.userUuid,
                      "options": [{
                        "name": <i>All</i>,
                        "value": null,
                      }].concat(filterSearchUsers.map((item) => ({
                        "name": <OzUserTag
                            name={item.givenName + " " + item.familyName}
                            company={item.company}
                            direction={"horizontal"}
                          />,
                        "value": item.uuid,
                      })))
                    },{
                      "size": 2,
                      "type": "clear",
                      "callback": () => {
                        setFilters(DEFAULT_FILTERS);
                        // localStorage.removeItem("ozwolConsoleFilterInsiderProjects"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))
                      }
                    }
                  ]
                }
              />
              <hr/>

            
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "Project",
                        "Owner"
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          values: [
                            <OzProjectTag
                              name={item.name}
                              uuid={item.uuid}
                              color={item.color}
                            />,
                            listUsers.filter(val => val.uuid === item.ownerUuid).length > 0 ?
                              listUsers.filter(val => val.uuid === item.ownerUuid).map(user =>
                                <OzUserTag
                                  key={user.uuid}
                                  name={user.givenName + " " + user.familyName}
                                  email={user.email}
                                />
                              )
                            : "-"
                          ],
                          link: ({children}) => (<Link to={"/insider/projects/"+item.uuid}>{children}</Link>)
                        }))
                      }
                    />
                  </TableWrapper>
                </>
              : <NoResult />}           
            </OzCard>          
          </HealthyWrapper>    
        </OzCol>
      </OzRow>
    </OzContainer>
  )
}

export default InsiderProjectsList;
