import React, {useState, useRef} from 'react';

const OverlayContext = React.createContext();

const OverlayContextProvider = ({children}) => {
  const [overlay, setOverlay] = useState([]);
  const overlayRef = useRef([]);
  overlayRef.current = overlay;

  const addOverlay = (item) => {
    setOverlay(overlayRef.current.concat(item));
  };

  const replaceOverlay = (item, reference = null) => {
    if(reference){
      let tempOverlay = [...overlay];
      let index = overlayRef.current.map(item => item.reference).indexOf(reference);
      tempOverlay[index] = item;
      setOverlay(tempOverlay);
    }else{
      setOverlay(overlayRef.current.slice(0, -1).concat(item));
    }
  };

  const removeOverlay = (reference = null) => {
    if(reference){
      setOverlay(overlayRef.current.filter(item => !item.reference || item.reference !== reference));
    }else{
      setOverlay(overlayRef.current.slice(0, -1));
    }
  };

  return (
    <OverlayContext.Provider value={{overlay, addOverlay, replaceOverlay, removeOverlay}}>
      {children}
    </OverlayContext.Provider>
  )
}

export {OverlayContextProvider, OverlayContext}
