import React from 'react';
import styled from 'styled-components';

import {BITTERSWEET, OzCard} from "@ozwol/webui";

const Text = styled.div`
  color: ${BITTERSWEET};
  background: #FFE2E2;
  padding: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
`;

const ModalError = ({children}) => {
  return (
    <OzCard>
      {children ? 
        <Text>
          {children}
        </Text>
      : null }
    </OzCard>  
  )

}

export default ModalError;
