//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import styled from 'styled-components';

import {BITTERSWEET, OzContainer, OzButton, OzTable, OzCol, OzRow, OzInput, OzCard, OzProjectTag, OzCheckbox, OzTooltip} from "@ozwol/webui";

import ApiKeysService from '../../services/ApiKeysService';

import ModalSuccess from '../../components/ModalSuccess';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, testValidField} from '../../helpers';


const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2),
  & table tr td:nth-child(3),
  & table tr td:nth-child(5){
    word-break: break-all;
    white-space: unset;
  }
`;
const ProjectsWrapper = styled.div`
  width: 100%;
  max-height: ${props => props.show ? "300px" : "0px"};
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s ease-in-out;

`;

const ApiKeysModalNew = ({projects, onSuccess}) => {
  const {replaceOverlay, removeOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState({
    "projectsUuid": []
  });

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(item.description, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  const save = () => {
    ApiKeysService.create(item).then((response) => {
      replaceOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      }, "form");
      onSuccess();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  };


  return (
    <OzContainer>
      
      <HealthyWrapper loading={!projects}>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Add API Key"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("form")}>Back</OzButton>}
          footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
        >
          <OzRow>
            <OzCol widthmd="12">
              <OzInput
                label="Description"
                width="100%"
                value={item && item.description ? item.description : ""}
                highlighted={item && item.description !== ""}
                placeholder="Description"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.description = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzCheckbox
                label="Active"
                selected={item && item.active ? item.active : null}
                highlighted={item && item.active !== ""}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.active = val;
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzCheckbox
                toggle={true}
                label={<>All projects <OzTooltip>Enables access to all current and future projects of the account. To limit access to specific projects, select the projects from the list below</OzTooltip></>}
                selected={item && item.projectsUuid && item.projectsUuid.length > 0 && item.projectsUuid[0] === "*" ? true : null}
                onChange={(val) => {
                  let tempItem = {...item};       
                  if(val){
                    tempItem.projectsUuid = ["*"];
                  }else{
                    tempItem.projectsUuid = [];
                  }
                  setItem(tempItem);
                }}
              />
            </OzCol>
            <br/><br/><br/>
            <OzCol widthmd="12">
              <ProjectsWrapper show={!item || !item.projectsUuid || (item.projectsUuid.length === 0 || (item.projectsUuid.length > 0 && item.projectsUuid[0] !== "*"))}>
                <TableWrapper>
                  <OzTable
                    selectable={true}
                    selected={item && item.projectsUuid ? item && item.projectsUuid : []}
                    onSelect={(ids) => {
                      let tempItem = {...item};
                      let tempSelected = [...item.projectsUuid];
                      ids.forEach(id => {
                        if(!item.projectsUuid.includes(id)){
                          tempSelected.push(id);
                        }else{
                          tempSelected = tempSelected.filter(e => e !== id);
                        }                    
                      });
                      tempItem.projectsUuid = [...tempSelected];
                      setItem(tempItem);
                    }}
                    values={
                      projects.map(item => ({
                        id: item.uuid,
                        style: !item.active ? {
                          background: BITTERSWEET+"33",
                          backgroundHover: BITTERSWEET+"55"
                        } : {},
                        values: [
                          <OzProjectTag
                            direction="horizontal"
                            key={item.uuid}
                            name={item.name}
                            uuid={item.uuid}
                            color={item.color}
                          />
                        ]
                      }))
                    }
                  />
                </TableWrapper>
              </ProjectsWrapper>
            </OzCol>
            
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default ApiKeysModalNew;
