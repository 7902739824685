import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from './../../contexts/OverlayContext';
import {useParams} from 'react-router-dom';

import {OzContainer, OzRow, OzCol, OzCard, OzButton, OzInput, OzSelect, OzCheckbox, OzTag} from '@ozwol/webui';

import PlansService from '../../services/PlansService';

import InsiderPlansModalNew from './InsiderPlansModalNew';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ModalSuccess from '../../components/ModalSuccess';
import ErrorMessage from '../../components/ErrorMessage';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {clearObject, testValidField, formatError} from '../../helpers';


const InsiderPlansSingle = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const {uuid} = useParams();
  const [item, setItem] = useState();
  const [originalItem, setOriginalItem] = useState(null);
  const [updatableFields, setUpdatableFields] = useState([]);

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  const [fetchError, setFetchError] = useState(false);


  const getItem = useCallback(() => {
    PlansService.adminGet(uuid).then((response) => {
      setItem(response);
      setOriginalItem(response);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
    PlansService.listUpdatableFields(uuid).then((response) => {
      setUpdatableFields(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
    
  }, [uuid]);

  const toggleActiveItem = useCallback(() => {
    PlansService.adminUpdate(item.uuid, {active: !item.active}).then((response) => {
      getItem();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item, getItem]);

  const deleteItem = useCallback(() => {
    PlansService.adminDelete(item.uuid).then((response) => {
      window.location.replace("/insider/plans");
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item]);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    PlansService.adminUpdate(uuid, clearObject(item, updatableFields)).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setOriginalItem(item);
    }).catch((error) => {
      console.log(error);
      setErrorMessage(formatError(error));
    });
  }, [uuid, item, addOverlay, updatableFields]);

  useEffect(() => {
    getItem();
  }, [uuid, getItem]);


  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.name, true) && updatableFields.includes("name")){
        valid = false;
      }
      if(!testValidField(item.credits, true) && updatableFields.includes("credits")){
        valid = false;
      }
      if(item.planType !== "COMPLIMENTARY"){
        if(!testValidField(item.price, true) && updatableFields.includes("price")){
          valid = false;
        }
        if(!testValidField(item.periodDuration, true) && updatableFields.includes("periodDuration")){
          valid = false;
        }
        if(!testValidField(item.rechargePrice, true) && updatableFields.includes("rechargePrice")){
          valid = false;
        }
        if(!testValidField(item.rechargeCredits, true) && updatableFields.includes("rechargeCredits")){
          valid = false;
        }
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem, updatableFields]);

  
  return (
    <>
      <Meta title={(item && item.name ? item.name : "") + " - Plans - Insider"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="amp_stories"
          breadcrumbsText={[
            <>{item && item.name ? item.name : ""} {item && !item.active ? <OzTag variant="bittersweet" size="small">DISABLED</OzTag> : null }</>
          ]}
          refScrollerPage={refScrollerPage}
          back={"/insider/plans"}
          buttons={[
            {
              "icon": "add",
              "text": "Add",
              "onClick": () => addOverlay({
                reference: "form",
                content: <InsiderPlansModalNew />
              })
            },
            {
              "icon": item && !item.active ? "visibility" : "visibility_off",
              "text": item && !item.active ? "Enable" : "Disable",
              "onClick": () => toggleActiveItem()
            },
            {
              "icon": "delete",
              "text": "Delete",
              "prompt": "DELETE",
              "onClick": () => deleteItem()
            }
          ]}
        />

        <HealthyWrapper error={fetchError} loading={!item}>
          {item ?
            <OzRow>
              <OzCol>
                <OzCard
                  headerLeft={
                    <CardHeaderTitle title={"Plan info"} />
                  }
                  headerSeparator={true}
                  footerRight={<OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
                >
                  <OzRow>
                    <OzCol widthmd="12">
                      <OzInput
                        label="UUID"
                        width="100%"
                        value={item.uuid}
                        placeholder="UUID"
                        copyable={true}
                        disabled
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Name"
                        width="100%"
                        value={item && item.name ? item.name : ""}
                        highlighted={item && originalItem && item.name !== originalItem.name}
                        placeholder="Name"
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.name = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!updatableFields.includes("name")}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Description"
                        width="100%"
                        value={item && item.description ? item.description : ""}
                        highlighted={item && originalItem && item.description !== originalItem.description}
                        placeholder="Description"
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.description = val;
                          setItem(tempItem);
                        }}
                        disabled={!updatableFields.includes("description")}
                      />
                    </OzCol>
                    <OzCol widthmd="3">
                      <OzCheckbox
                        label="Active"
                        labelSpacing
                        selected={item && item.active}
                        highlighted={item && originalItem && item.active !== originalItem.active}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.active = val;
                          setItem(tempItem);
                        }}
                        disabled={!updatableFields.includes("active")}
                      />
                    </OzCol>
                    <OzCol widthmd="3">
                      <OzCheckbox
                        label="Custom"
                        labelSpacing
                        selected={item && item.isCustom}
                        highlighted={item && originalItem && item.isCustom !== originalItem.isCustom}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.isCustom = val;
                          setItem(tempItem);
                        }}
                        disabled={!updatableFields.includes("isCustom")}
                      />
                    </OzCol>
                    {item.planType !== "COMPLIMENTARY" ?
                      <OzCol widthmd="6">
                        <OzSelect
                          label="Duration"
                          width="100%"
                          placeholder={"Type"}
                          values={[
                            {
                              "name": "Monthly",
                              "value": "MONTHLY"
                            },
                            {
                              "name": "Semiannually",
                              "value": "SEMIANNUALLY"
                            },
                            {
                              "name": "Annually",
                              "value": "ANNUALLY"
                            }
                          ]}
                          selected={item && item.periodDuration ? item.periodDuration : null}
                          highlighted={item && originalItem && item.periodDuration !== originalItem.periodDuration}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.periodDuration = val;
                            setItem(tempItem);
                          }}
                          maxHeight={"200px"}
                          required
                          disabled={!updatableFields.includes("periodDuration")}
                        />
                      </OzCol>
                    : null }
                    {item.planType !== "COMPLIMENTARY" ?
                      <OzCol widthmd="6">
                        <OzInput
                          label="Price"
                          width="100%"
                          value={item && item.price ? item.price : ""}
                          highlighted={item && originalItem && item.price !== originalItem.price}
                          placeholder="Price"
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.price = val;
                            setItem(tempItem);
                          }}
                          required
                          disabled={!updatableFields.includes("price")}
                        />
                      </OzCol>
                    : null }
                    <OzCol widthmd="6">
                      <OzInput
                        label="Credits"
                        width="100%"
                        value={item && item.credits ? item.credits : ""}
                        highlighted={item && originalItem && item.credits !== originalItem.credits}
                        placeholder="Credits"
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.credits = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={!updatableFields.includes("credits")}
                      />
                    </OzCol>
                    {/* <OzCol widthmd="6">
                      gateway?
                    </OzCol> */}
                    {item.planType !== "COMPLIMENTARY" ?
                      <>
                        <OzCol widthmd="6">
                          <OzInput
                            label="Recharge price"
                            width="100%"
                            value={item && item.rechargePrice ? item.rechargePrice : ""}
                            highlighted={item && originalItem && item.rechargePrice !== originalItem.rechargePrice}
                            placeholder="Recharge price"
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.rechargePrice = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("rechargePrice")}
                            required
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzInput
                            label="Recharge credits"
                            width="100%" 
                            value={item && item.rechargeCredits ? item.rechargeCredits : ""}
                            highlighted={item && originalItem && item.rechargeCredits !== originalItem.rechargeCredits}
                            placeholder="Recharge credits"
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.rechargeCredits = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("rechargeCredits")}
                            required
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzCheckbox
                            label="Credit Rollover"
                            labelSpacing
                            selected={item && item.creditRollOver}
                            highlighted={item && originalItem && item.creditRollOver !== originalItem.creditRollOver}
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.creditRollOver = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("creditRollOver")}
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzInput
                            label="Credit Rollover Limit"
                            width="100%" 
                            value={item && item.creditRollOverLimit ? item.creditRollOverLimit : ""}
                            highlighted={item && originalItem && item.creditRollOverLimit !== originalItem.creditRollOverLimit}
                            placeholder="Credit Rollover Limit"
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.creditRollOverLimit = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("creditRollOverLimit")}
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzInput
                            label="Projects Limit"
                            width="100%"
                            value={item && item.projectsLimit ? item.projectsLimit : ""}
                            highlighted={item && originalItem && item.projectsLimit !== originalItem.projectsLimit}
                            placeholder="Projects Limit"
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.projectsLimit = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("projectsLimit")}
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzInput
                            label="Hotfolders Limit"
                            width="100%"
                            value={item && item.hotfoldersLimit ? item.hotfoldersLimit : ""}
                            highlighted={item && originalItem && item.hotfoldersLimit !== originalItem.hotfoldersLimit}
                            placeholder="Hotfolders Limit"
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.hotfoldersLimit = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("hotfoldersLimit")}
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzInput
                            label="Storage Credit GB"
                            width="100%"
                            value={item && item.storageCreditGb ? item.storageCreditGb : ""}
                            highlighted={item && originalItem && item.storageCreditGb !== originalItem.storageCreditGb}
                            placeholder="Storage Credit GB"
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.storageCreditGb = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("storageCreditGb")}
                          />
                        </OzCol>
                        <OzCol widthmd="6">
                          <OzInput
                            label="Transfer Credit GB"
                            width="100%"
                            value={item && item.transferCreditGb ? item.transferCreditGb : ""}
                            highlighted={item && originalItem && item.transferCreditGb !== originalItem.transferCreditGb}
                            placeholder="Transfer Credit GB"
                            onChange={(val) => {
                              let tempItem = {...item};
                              tempItem.transferCreditGb = val;
                              setItem(tempItem);
                            }}
                            disabled={!updatableFields.includes("transferCreditGb")}
                          />
                        </OzCol>
                      </>
                    : null }
                    
                    <OzCol widthmd="3">
                      <OzCheckbox
                        label="Team allowed"
                        labelSpacing
                        selected={item && item.teamAllowed}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.teamAllowed = val;
                          setItem(tempItem);
                        }}
                        disabled={!updatableFields.includes("teamAllowed")}
                      />
                    </OzCol>
                    
                    <OzCol widthmd="3">
                      <OzCheckbox
                        label="API allowed"
                        labelSpacing
                        selected={item && item.apiAllowed}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.apiAllowed = val;
                          setItem(tempItem);
                        }}
                        disabled={!updatableFields.includes("apiAllowed")}
                      />
                    </OzCol>
                  </OzRow>
                  <ErrorMessage>
                    {errorMessage}
                  </ErrorMessage>
                </OzCard>
              </OzCol>
            </OzRow>
          : null}
        </HealthyWrapper>

      </OzContainer>
    </>
  )
}

export default InsiderPlansSingle;
