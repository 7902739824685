import React, {useContext} from 'react';
import {OverlayContext} from './../contexts/OverlayContext';
import styled from 'styled-components';

import {OzCard, OzButton} from "@ozwol/webui";

const Wrapper = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 350px;

  text-align: left;
  z-index: 999;

  & > *{
    box-shadow: #c1cce890 0px 3px 2px;
  }
`;

const Close = styled.div`
  cursor: pointer;
  & *{
    font-size: 20px;
  }
`;

const PopupNewsletter = ({fnClose}) => {
  const {removeOverlay} = useContext(OverlayContext);	
  return (
    <Wrapper>
      <OzCard
        variant={"lilac"}
        headerLeft={"Stay Ahead with Ozwol"}
        headerRight={
          <Close onClick={() => {removeOverlay("popupNewsletter"); fnClose();}}>
            <span className="material-symbols-outlined">close</span>
          </Close>
        }
      >
        Subscribe to Ozwol's newsletter to receive the latest updates directly in your inbox.<br/><br/>
        <a href="https://mailchi.mp/439dfc71a8d0/newsletter-subscription" target="_blank" rel="noreferrer"><OzButton variant={"chetwodeblue-o"}>Subscribe now</OzButton></a>
      </OzCard>
    </Wrapper>
  
  )
}

export default PopupNewsletter;
