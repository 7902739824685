import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import styled from 'styled-components';
import {CDN} from './../../config';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable, OzButton, OzTag} from "@ozwol/webui";

import CoworkersService from '../../services/CoworkersService';
import ProjectsService from '../../services/ProjectsService';

// import Success from '../../components/Success';
import Meta from '../../components/Meta';
import ModalError from '../../components/ModalError';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import PromptedButton from '../../components/PromptedButton';

import TeamModalNew from './MembersModalNew';
import TeamModalEdit from './MembersModalEdit';

import {OVERLAY_TIMEOUT_ERROR} from '../../config';
import {formatError, getFormattedDate, checkPermission} from '../../helpers';


const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(1),
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
`;
const NoResult = styled.div`
  text-align: center;
  font-weight: bold;

  & > img{
  width: 40%;

`;


const Members = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const [list, setList] = useState(null);
  const [listProjects, setListProjects] = useState(null);

  const [fetchError, setFetchError] = useState(false);



  const getList = useCallback(() => {
    CoworkersService.list().then((response) => {
      setList(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, []);

  const deleteItem = useCallback((item) => {
    setList(null);
    CoworkersService.delete(item.uuid).then((response) => {
      getList();
    }).catch((error) => {
      addOverlay({
        reference: "error",
        content: <ModalError>{formatError(error)}</ModalError>,
        timeout: OVERLAY_TIMEOUT_ERROR
      });
    });
  }, [getList, addOverlay]);

  useEffect(() => {
    getList();
    ProjectsService.list().then((response) => {
      setListProjects(response.result);
    });
  }, [getList]); 


  return (
    <OzContainer size="extra">
      <Meta title={"Team members"} />
      <PageHeader
        breadcrumbsIcon="group"
        breadcrumbsText={["Team members", "Team"]}
        refScrollerPage={refScrollerPage}
      />
        
      <HealthyWrapper error={fetchError} loading={!list || !listProjects}>
        <OzRow>
          <OzCol>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"Team members"}
                />
              }
              headerSeparator={true}
              footerRight={checkPermission("coworker", "create") ? 
                <OzButton 
                  onClick={() => addOverlay({
                    reference: "form",
                    content: <TeamModalNew projects={listProjects} onSuccess={() => getList()} />
                  })}
                  variant={"chetwodeblue"}
                >Add</OzButton> 
              : null}
            >
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "Name",
                        "Email",
                        "Invitation date",
                        "Role",
                        "Projects",
                        "Status",
                        ""
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          values: [
                            item.givenName + " " + item.familyName,
                            item.email,
                            getFormattedDate(item.invitationDatetime),
                            item.role,
                            item && item.permissions && item.permissions.project && item.permissions.project.length > 0 ? (item.permissions.project[0] === "*" ? "All" : item.permissions.project.length) : "-",
                            <OzTag variant={
                              item.status === "OPERATIVE" ? "silvertree" : 
                              item.status === "PENDING" ? "mustard" : 
                              item.status === "EXPIRED" ? "bittersweet" : 
                              null
                            }>{item.status}</OzTag>,
                            <>
                              {checkPermission("coworker", "read") ? 
                                <OzButton 
                                  onClick={() => addOverlay({
                                    reference: "form",
                                    content: <TeamModalEdit uuid={item.uuid} projects={listProjects} onSuccess={() => getList()} />
                                  })} 
                                  noMinWidth={true} 
                                  variant="push-black" 
                                  size="small"
                                ><span className="material-symbols-outlined">edit</span></OzButton>
                              : null}
                              &nbsp;
                              {checkPermission("coworker", "delete") ? 
                                <PromptedButton 
                                  buttonVariant="push-bittersweet" 
                                  buttonSize={"small"} 
                                  buttonText={<span className="material-symbols-outlined">delete</span>} 
                                  prompt="DELETE"
                                  buttonNoMinWidth={true}
                                  onConfirm={() => deleteItem(item)} 
                                /> 
                              : null }
                              
                            </>
                          ]
                        }))
                      }
                    />
                  </TableWrapper>
                </>
              : 
                <NoResult>
                  <img src={CDN + "console-assets/no-members.svg"} alt="no members" /><br/><br/>
                  There are no active members.<br/>
                  Click Add to get started.
                </NoResult>
              }   

            </OzCard>
          </OzCol>
        </OzRow>
      </HealthyWrapper>
    </OzContainer>
  );
}

export default Members;
