import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {OverlayContext} from './../../contexts/OverlayContext';

import {BORDER, OzContainer, OzRow, OzCol, OzCard, OzTypo, OzButton, OzSelect} from '@ozwol/webui';

import AccountService from './../../services/AccountService';
import PlansService from './../../services/PlansService';

import ModalSuccess from '../../components/ModalSuccess';
import ModalActivate from '../../chunks/ModalActivate';
import Meta from './../../components/Meta';
import PageHeader from '../../components/PageHeader';
import ErrorMessage from '../../components/ErrorMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import ReferencePeriodTimeline from './../../common/ReferencePeriodTimeline';


import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {checkPermission, formatError, convertNumber, formatSingleCreditPrice} from './../../helpers';


const Note = styled.div`
  text-align: ${props => props.align};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const PlanSlotName = styled.div`
  height: 60px;
`;
const PlanSlotInfo1 = styled.div`
  height: 120px;
`;
const PlanSlotInfo2 = styled.div`
  height: 120px;
`;
const PlanSlotPrice = styled.div`
  border-top: 1px solid #d5d9ed;
  padding-top: 10px;
  height: 60px;
`;
const PlanSlotCta = styled.div`
  text-align: center;
`;
const PlanIcons = styled.span`
  & > *{
    font-size: 14px;
    position: relative;
    top: 2px;
  }
`;

const NoCard = styled.div`
  padding: 20px 30px 30px;
  border: 1px solid transparent;
`;
const Detail = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid ${BORDER};
  white-space: ${props => props.multiline ? "wrap" : "nowrap"};
  width: 100%;
`;
const Center = styled.div`
  & *{
    text-align: center;
    margin: 0 auto;
  }
`;
const ScriptCategory = styled.div`
  border-radius: 4px;
  position: relative;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 16px;
  background-color: ${props => props.background};
  color: ${props => props.color};
`;
const ScriptCategoryNote = styled.div`
  position: absolute;
  font-size: 10px;
  bottom: 5px;
  right: 10px;
`;
const ScriptCost = styled.div`
  padding: 0px 30px;
  font-size: 25px;
`;
const ScriptCostNote = styled.div`
  font-size: 11px;
  margin-top: 5px;
`;


const Plans = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const [user, setUser] = useState(null);
  const [period, setPeriod] = useState(null);
  const [plans, setPlans] = useState(null);
  const [prices, setPrices] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const [fetchError, setFetchError] = useState(false);
  
  const [planInfo, setPlanInfo] = useState(false);


  const getUser = useCallback(() => {
    AccountService.getUser().then((response) => {
      setUser(response);

      setPlanInfo(response.currentPlan ? response.currentPlan.uuid : (response.candidatePlan ? response.candidatePlan.uuid : null));      
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, []);

  const activatePlan = useCallback((plan) => {
    AccountService.updateUser({
      candidatePlan: plan
    }).then((response) => {
      setUser(response)
      addOverlay({
        reference: "payment-method",
        content: <ModalActivate />
      });
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [addOverlay]);

  const changeOfficialPlan = useCallback((uuid) => {
    AccountService.changePlan(uuid).then((response) => {   
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      getUser();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [addOverlay, getUser]);

  const changeCustomPlan = useCallback((uuid) => {
    AccountService.changePlan(uuid).then((response) => {   
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your request has been submitted!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      getUser();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [addOverlay, getUser]);

  useEffect(() => {  
    getUser();
    AccountService.getPeriod().then((response) => {
      setPeriod(response);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
    PlansService.list().then((response) => {
      setPlans(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    }); 
    PlansService.getPrices().then((response) => {
      setPrices(response);
    }).catch((error) => {
      setFetchError(formatError(error));
    });  
    
  }, [getUser]);

  return (
    <>
      <OzContainer size={"extra"}>
        <Meta title={"Plans"} />
        <PageHeader
          breadcrumbsIcon="amp_stories"
          breadcrumbsText={["Plans", "Account"]}
          refScrollerPage={refScrollerPage}
        />

        <HealthyWrapper error={fetchError} loading={!user || !plans}>
          {user && plans ?
            <OzRow>
              <OzCol widthmd="12">
                <OzCard>
                  <ReferencePeriodTimeline
                    period={period}
                    user={user}
                    plansPage={true}
                    showCta={false}
                  />
                </OzCard>
              </OzCol>

              { user.planChangeable ?
                <>
                  {/* can change plan */}
                  <OzCol widthmd="3">
                    <Note align={"left"}>
                      <OzTypo variant="notes">
                        Storage costs 300 credits per gigabyte per month.<br/>
                        Bandwidth costs 300 credits per gigabyte in download, not charged in upload.
                      </OzTypo>
                    </Note>
                  </OzCol>
                  {plans && plans.length > 0 ? plans.map((plan, i) => (
                    <OzCol widthmd="3" key={i}>
                      <OzCard variant={(user.candidatePlan && user.candidatePlan.uuid === plan.uuid) || period.currentPlan.uuid === plan.uuid ? "lilac" : "default"}>
                        <PlanSlotName>
                          <OzTypo variant="h1c">{plan.name}</OzTypo>
                        </PlanSlotName>
                        <PlanSlotInfo1>
                          Included credits: {convertNumber(plan.credits)}<br/>
                          Single credit cost: €{formatSingleCreditPrice(plan.price/plan.credits)}<br/>
                          {parseInt(plan.rechargeCredits) !== 0 && parseInt(plan.rechargePrice) !== 0 ? 
                            <>Extra recharge credits: {convertNumber(plan.rechargeCredits)} / €{convertNumber(plan.rechargePrice)}<br/></>
                          : null }
                        </PlanSlotInfo1>
                        <PlanSlotInfo2>
                          <PlanIcons><span className="material-symbols-outlined">business_center</span></PlanIcons> {plan.projectsLimit > 0 ? convertNumber(plan.projectsLimit) : "Unlimited"} projects<br/>
                          <PlanIcons><span className="material-symbols-outlined">folder</span></PlanIcons> {plan.hotfoldersLimit > 0 ? convertNumber(plan.hotfoldersLimit) : "Unlimited"} hotfolders<br/>
                        </PlanSlotInfo2>
                        <PlanSlotPrice>
                          <OzTypo variant="h1c">€{plan.price}</OzTypo>
                        </PlanSlotPrice>
                        {checkPermission("subPlan", "update") ?
                          <PlanSlotCta>
                            {period.currentPlan.uuid === plan.uuid && (user.nextPlan && user.nextPlan.uuid === plan.uuid) ? 
                              <OzButton
                                variant="push-black"
                                disabled
                              >Active</OzButton>
                            : (user.nextPlan && user.nextPlan.uuid === plan.uuid) ? 
                              <OzButton
                                variant="push-black"
                                disabled
                              >Next</OzButton>
                            :
                              <>
                                {user.company && user.address && user.city && user.zip && user.state && user.countryId && user.vatNumber && (user.sdiNumber || user.emailPec) ? 
                                  <OzButton
                                    onClick={
                                      period.currentPlan.planType === "PAID" ? 
                                        () => changeOfficialPlan(plan.uuid)
                                      :
                                        () => activatePlan(plan)
                                    }
                                    variant="chetwodeblue"
                                  >Confirm</OzButton>
                                : 
                                  <Link to="/account/billinginfo">
                                    <OzButton variant="chetwodeblue">Please fill in your billing details</OzButton>
                                  </Link>
                                }
                              </>
                            }
                          </PlanSlotCta>
                        : null}
                      </OzCard>                 
                    </OzCol>                    
                  )) : null}
                  

                  {/* DETTAGLI */}
                  <OzCol widthmd="3">
                    <NoCard>
                      <Detail multiline={false}>Montly cost</Detail>
                      <Detail multiline={false}>Included credits</Detail>
                      <Detail multiline={false}>Single credit cost</Detail>
                      <Detail multiline={false}>Extra recharge credits</Detail>
                      <Detail multiline={false}>Hotfolders</Detail>
                      <Detail multiline={false}>Projects </Detail>
                      <Detail multiline={false}>Credit Rollover</Detail>
                      <Detail multiline={false}>Teams</Detail>
                      <Detail multiline={false}>API</Detail>
                    </NoCard>
                  </OzCol>
                  {plans.map((plan, i) => (
                    <OzCol widthmd="3" key={i}>
                      <OzCard variant={"default"}>
                        <Detail multiline={false}>€{convertNumber(plan.price, 0)}</Detail>
                        <Detail multiline={false}>{convertNumber(plan.credits, 0)}</Detail>
                        <Detail multiline={false}>{convertNumber(parseFloat((plan.price/plan.credits).toFixed(4)))}€</Detail>
                        <Detail multiline={false}>{convertNumber(plan.rechargeCredits)} / €{convertNumber(plan.rechargePrice)}</Detail>
                        <Detail multiline={false}>{plan.hotfoldersLimit > 0 ? convertNumber(plan.hotfoldersLimit, 0) : "Unlimited"}</Detail>
                        <Detail multiline={false}>{plan.projectsLimit > 0 ? convertNumber(plan.projectsLimit, 0) : "Unlimited"}</Detail>
                        <Detail multiline={false}>{plan.creditRollOver > 0 ? "Up to a max of " + convertNumber(plan.creditRollOverLimit) : "-"}</Detail>
                        <Detail multiline={false}>{plan.teamAllowed ? "Yes" : "-"}</Detail>
                        <Detail multiline={false}>{plan.apiAllowed ? "Yes" : "-"}</Detail>
                      </OzCard>
                    </OzCol>                  
                  ))}
                  
                  {prices && prices.length > 0 ? 
                    <OzCol widthmd="12">
                      <Center>
                        <br/><br/>
                        <OzTypo variant={"h1c"}>Price per image</OzTypo>
                        {/* <OzTypo variant={"h2c"}>Prices valid from April 1st 2024.</OzTypo> */}
                        <OzTypo variant={"h2c"}>The price in euros calculated on the basis of your plan.</OzTypo>
                        <br/>
                        <OzSelect 
                          selected={planInfo}
                          maxHeight={"400px"}
                          width={"200px"}
                          values={plans && plans.length > 0 ? plans.map((item) => ({
                            "name": item.name,
                            "value": item.uuid
                          })) : []}
                          onChange={(val) => setPlanInfo(val)}
                        />
                        <br/><br/>
                      </Center>
                    </OzCol>
                  : null }

                  {prices && prices.length > 0 ? prices.map((price, i) => (
                    <OzCol widthmd="4" key={i}>
                      <ScriptCategory background={price.color} color={"#ffffff"}>
                        {price.category}
                        <ScriptCategoryNote>{price.note}</ScriptCategoryNote>
                      </ScriptCategory>
                      <br/>
                      <ScriptCost>
                        {plans.filter(item => item.uuid === planInfo).length > 0 ? convertNumber(parseFloat((plans.filter(item => item.uuid === planInfo)[0].price/plans.filter(item => item.uuid === planInfo)[0].credits).toFixed(4))*price.credits) : "-"}€
                        <ScriptCostNote>{price.credits} credits</ScriptCostNote>
                      </ScriptCost>
                      <br/>
                      {price.operations && price.operations.length > 0 ?
                        <OzCard variant={"default"}>
                          {price.operations.map((operation, j) => (
                            <Detail key={j} multiline={true}>{operation}</Detail>             
                          ))}                    
                        </OzCard>
                      : null}
                    </OzCol>                 
                  )) : null }

                </>
              : 
                //can't change plan
                <>
                  <OzCol widthmd="4"></OzCol>
                  <OzCol widthmd="4">
                    <OzCard variant={"lilac"}>
                      <PlanSlotName>
                        <OzTypo variant="h1c">{user.candidatePlan ? user.candidatePlan.name: period.currentPlan.name}</OzTypo>
                      </PlanSlotName>
                      <PlanSlotInfo1>
                        Included credits: {convertNumber(user.candidatePlan ? user.candidatePlan.credits: period.currentPlan.credits)}<br/>
                        Single credit cost: €{formatSingleCreditPrice((user.candidatePlan ? user.candidatePlan.price: period.currentPlan.price)/(user.candidatePlan ? user.candidatePlan.credits: period.currentPlan.credits))}<br/>
                        {parseInt(user.candidatePlan ? user.candidatePlan.rechargeCredits: period.currentPlan.rechargeCredits) !== 0 && parseInt(user.candidatePlan ? user.candidatePlan.rechargePrice: period.currentPlan.rechargePrice) !== 0 ? 
                          <>Extra recharge credits: {convertNumber(user.candidatePlan ? user.candidatePlan.rechargeCredits: period.currentPlan.rechargeCredits)} / €{convertNumber(user.candidatePlan ? user.candidatePlan.rechargePrice: period.currentPlan.rechargePrice)}<br/></>
                        : null }
                      </PlanSlotInfo1>
                      {user.candidatePlan ?
                        <PlanSlotInfo2>
                          <PlanIcons><span className="material-symbols-outlined">business_center</span></PlanIcons> {user.candidatePlan.projectsLimit > 0 ? convertNumber(user.candidatePlan.projectsLimit) : "Unlimited"} projects<br/>
                          <PlanIcons><span className="material-symbols-outlined">folder</span></PlanIcons> {user.candidatePlan.hotfoldersLimit > 0 ? convertNumber(user.candidatePlan.hotfoldersLimit) : "Unlimited"} hotfolders<br/>
                        </PlanSlotInfo2>
                      : 
                        <PlanSlotInfo2>
                          <PlanIcons><span className="material-symbols-outlined">business_center</span></PlanIcons> {period.currentPlan.projectsLimit > 0 ? convertNumber(period.currentPlan.projectsLimit) : "Unlimited"} projects<br/>
                          <PlanIcons><span className="material-symbols-outlined">folder</span></PlanIcons> {period.currentPlan.hotfoldersLimit > 0 ? convertNumber(period.currentPlan.hotfoldersLimit) : "Unlimited"} hotfolders<br/>
                        </PlanSlotInfo2>
                      }
                      <PlanSlotPrice>
                        <OzTypo variant="h1c">€{user.candidatePlan ? user.candidatePlan.price: period.currentPlan.price}</OzTypo>
                      </PlanSlotPrice>
                      {checkPermission("subPlan", "update") ?
                        <PlanSlotCta>
                          {user.candidatePlan && period.currentPlan.uuid !== user.candidatePlan.uuid ? 
                            <>
                              {user.company && user.address && user.city && user.zip && user.state && user.countryId && user.vatNumber && (user.sdiNumber || user.emailPec) ? 
                                <OzButton
                                  onClick={
                                    period.currentPlan.planType === "PAID" ? 
                                      () => changeCustomPlan(user.candidatePlan.uuid)
                                    :
                                      () => activatePlan(user.candidatePlan)
                                  }
                                  variant="chetwodeblue"
                                >Confirm</OzButton>
                              : 
                                <Link to="/account/billinginfo">
                                  <OzButton variant="chetwodeblue">Please fill in your billing details</OzButton>
                                </Link>
                              }
                            </>
                          : null }
                        </PlanSlotCta>
                      : null}
                    </OzCard>                 
                  </OzCol>
                  {!user.nextPlan ?   
                    <OzCol>
                      <center>
                        Do you need to choose your next plan?<br/><a href="mailto:sales@ozwol.com">Contact us</a>.
                      </center>
                    </OzCol>
                  : null}
                  <OzCol>
                    <Note align={"center"}>
                      <OzTypo variant="notes">
                        Storage costs 300 credits per gigabyte per month.<br/>
                        Bandwidth costs 300 credits per gigabyte in download, not charged in upload.
                      </OzTypo>
                    </Note>
                  </OzCol>
                </>
              }
              
            </OzRow>
          : null }
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
        </HealthyWrapper>

      </OzContainer>
    </>
  )
}

export default Plans;
