import axios from 'axios';
import {queryParams} from '../helpers';

class JoinService {

  constructor() {
    const baseURL = process.env.REACT_APP_API_ENDPOINT;
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  join(params = null) {
    return new Promise((resolve, reject) => {
      this.instance.get('public/coworkers/joinTeam'+queryParams(params)).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
}

let service =  new JoinService();
export default service;