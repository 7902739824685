import React, {useState, useContext, useCallback} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../contexts/OverlayContext';

import {OzButton, OzCard, OzInput} from '@ozwol/webui';

import CognitoService from '../services/CognitoService';

import CardHeaderTitle from '../components/CardHeaderTitle';
import ErrorMessage from '../components/ErrorMessage';
import ModalSuccess from '../components/ModalSuccess';

import {OVERLAY_TIMEOUT_SUCCESS} from '../config';
import {formatError} from '../helpers';

const Container = styled.div`
  width: 450px;
`;


const ModalMFARemove = ({user, onSuccess}) => {
  const {replaceOverlay, removeOverlay} = useContext(OverlayContext);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const removeMfa = useCallback(() => {
    setErrorMessage(null);
    CognitoService.checkPassword(password).then((response) => {
      setErrorMessage(null);
      CognitoService.disableMFA().then((response) => {
        replaceOverlay({
          reference: "success",
          content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
          timeout: OVERLAY_TIMEOUT_SUCCESS
        }, "mfa");
        onSuccess();        
      }).catch((error) => {
        setErrorMessage(formatError(error));
      });
    }).catch((error) => {
      setErrorMessage("Wrong password.");
    });
  }, [password, replaceOverlay, onSuccess]);
  
  return (
    <Container>

      <OzCard
        headerLeft={
          <CardHeaderTitle 
            title={"Remove MFA"} 
          />
        }
        headerSeparator={true}
        footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("mfa")}>Cancel</OzButton>}
        footerRight={<OzButton variant={"bittersweet-o"} onClick={() => removeMfa()}>Remove</OzButton>}
      >
        Are you sure you want to remove Multi-Factor Authentication from your account?<br/>
        This will reduce the security of your account.

        <br/>
        <OzInput
          label="Password"
          type="password"
          toggleVisibility
          width="100%"
          value={password ? password : ""}
          onChange={(val) => {
            setPassword(val);
          }}
          required
        />

        <br/>

        <ErrorMessage noMargin>
          {errorMessage}
        </ErrorMessage>

      </OzCard>

    </Container>
  );

}

export default ModalMFARemove;
