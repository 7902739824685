import React, {useEffect, useContext, useRef} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../../contexts/OverlayContext';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #bdc2d6d9;

  overflow-x: hidden;
  overflow-y: auto;
`;

const Spacer = styled.div`
  flex-basis: 30px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  max-width: 90%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
`;

const Overlay = ({
  reference = "randRef",//+Math.floor(Math.random() * 9999999),
  type = "modal",
  // variant = "default",
  isCloseable = true, 
  fnClose = ()=>{}, 
  timeout = null, 
  children
}) => {  
  // console.log(reference);
  const {removeOverlay} = useContext(OverlayContext);
  const timeoutRef = useRef(null);
  
  useEffect(() => {
    if(timeout){
      timeoutRef.current = setTimeout(() => {
        removeOverlay(reference);
      }, timeout);
    }
    return () => {
      if(timeoutRef.current){
        clearTimeout(timeoutRef.current);
      }
    }
  }, [timeout, removeOverlay, reference]);

  switch(type){
    case "modal": 
      return (
        <Background onClick={(event) => {
          event.preventDefault();
          if(isCloseable){
            if(event.target === event.currentTarget || event.target.parentElement === event.currentTarget) {
              fnClose();
              removeOverlay(reference);
            }
          }
        }}>
          <Spacer />
          <Wrapper>
            {children}
          </Wrapper>
          <Spacer />
        </Background>
      )
    case "popup": 
      return children;
    default: 
      break;
  }
  
}

export default Overlay;
