export const BREAKPOINT_XS = 768 - 1;
export const BREAKPOINT_SM = 992 - 1;
export const BREAKPOINT_MD = 1400 - 1;

export const LAYOUT_XS = "XS";
export const LAYOUT_SM = "SM";
export const LAYOUT_MD = "MD";
export const LAYOUT_LG = "LG";

export const PAGESIZE = 20;

export const CDN = "https://cdn.ozwol.com/ozwol/";

export const FORCE_LOGOUT = 2;
export const POPUP = 1;


export const OVERLAY_TIMEOUT_SUCCESS = 2000;
export const OVERLAY_TIMEOUT_ERROR = 10000;