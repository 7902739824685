import React, {useState, useEffect, useCallback, useContext} from 'react';
// import {Link} from 'react-router-dom';
// import styled from 'styled-components';
import {OverlayContext} from './../../contexts/OverlayContext';
import {CognitoContext} from '../../contexts/CognitoContext';
import {OzContainer, OzRow, OzCol, OzCard, OzButton, OzInput} from '@ozwol/webui';

import AccountService from '../../services/AccountService';

import ReferencePeriodTimeline from '../../common/ReferencePeriodTimeline';

import ModalCloseAccount from './ModalCloseAccount';
import ModalSuccess from '../../components/ModalSuccess';
import Meta from '../../components/Meta';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ErrorMessage from '../../components/ErrorMessage';

import PageHeader from '../../components/PageHeader';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {checkPermission, formatError, testValidField, clearObject} from '../../helpers';


const Profile = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState();
  const [originalItem, setOriginalItem] = useState(null);
  const [period, setPeriod] = useState(null);
  const {cognito} = useContext(CognitoContext);

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageOpenClose, setErrorMessageOpenClose] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  
  // const [showModalDeletion, toggleModalDeletion] = useState(false);

  const getItem = useCallback(() => {
    // console.log(user);
    if(!cognito.groups.includes("OzwolCoworker")){
      AccountService.getUser().then((response) => {
        setItem(response);
        setOriginalItem(response);
      }).catch((error) => {
        setItem(null);
        setFetchError(formatError(error));
      });
    }else{
      AccountService.getCoworker(cognito.ParsedJwtToken.sub).then((response) => {
        setItem(response);
        setOriginalItem(response);
      }).catch((error) => {
        setItem(null);
        setFetchError(formatError(error));
      });
    }
  }, [cognito]);
      

  const getPeriod = useCallback(() => {
    if(checkPermission("period", "read")){
      AccountService.getPeriod().then((response) => {
        setPeriod(response);
      }).catch((error) => {
        setFetchError(formatError(error));
      }); 
    }
  }, []);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    AccountService.updateUser(clearObject(item, ["givenName","familyName","phoneNumber","email"])).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setOriginalItem(item);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item, addOverlay]);

  const reopen = useCallback(() => {
    AccountService.reopenAccount().then((response) => {
      getPeriod();
      getItem();
    }).catch((error) => {
      setErrorMessageOpenClose(formatError(error));
    });
  }, [getPeriod, getItem]);

  useEffect(() => {
    getItem();
    getPeriod();
  }, [getItem, getPeriod]);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.givenName, true)){
        valid = false;
      }
      if(!testValidField(item.familyName, true)){
        valid = false;
      }
      if(!testValidField(item.email, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem])

  return (
    <>
      <Meta title={"Profile"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="person"
          breadcrumbsText={["Profile", "Account"]}
          refScrollerPage={refScrollerPage}
        />

        <HealthyWrapper error={fetchError} loading={!item}>
          <OzRow>
            <OzCol>
              {item ? 
                <OzCard
                  headerLeft={
                    <CardHeaderTitle 
                      title={"Personal info"} 
                    />
                  }
                  headerSeparator={true}
                  footerRight={!cognito.groups.includes("OzwolCoworker") ? <OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton> : null}
                >
                  <OzRow>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Given name"
                        width="100%"
                        value={item && item.givenName ? item.givenName : ""}
                        highlighted={item && originalItem && item.givenName !== originalItem.givenName}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.givenName = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={cognito.groups.includes("OzwolCoworker")}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Family name"
                        width="100%"
                        value={item && item.familyName ? item.familyName : ""}
                        highlighted={item && originalItem && item.familyName !== originalItem.familyName}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.familyName = val;
                          setItem(tempItem);
                        }}
                        required
                        disabled={cognito.groups.includes("OzwolCoworker")}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput 
                        label="Email" 
                        width="100%" 
                        value={item && item.email ? item.email : ""} 
                        highlighted={item && originalItem && item.email !== originalItem.email}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.email = val;
                          setItem(tempItem);
                        }} 
                        required
                        disabled={cognito.groups.includes("OzwolCoworker")}
                      />
                    </OzCol>
                    {!cognito.groups.includes("OzwolCoworker") ? 
                      <OzCol widthmd="6">
                        <OzInput
                          label="Phone"
                          width="100%"
                          value={item && item.phoneNumber ? item.phoneNumber : ""}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.phoneNumber = val;
                            setItem(tempItem);
                          }}
                        />
                      </OzCol>
                    : null}
                  </OzRow>
                  <ErrorMessage>
                    {errorMessage}
                  </ErrorMessage>
                </OzCard>
              : null}
            </OzCol>

            {period && item && !cognito.groups.includes("OzwolCoworker") ? 
              <OzCol>
                {(item.status === "OPERATIVE" && !item.accountToBeClosed) ? 
                  <OzCard
                    headerLeft={"Close account"}
                    headerSeparator={true}
                    footerRight={<OzButton variant={"bittersweet"} onClick={() => addOverlay({
                      reference: "close-account",
                      content:
                        <ModalCloseAccount
                          period={period}
                          user={item}
                          fnSuccess={() => {
                            getPeriod();
                            getItem();
                          }}
                        />
                    })}>CLOSE ACCOUNT</OzButton>}
                  >
                    By closing your Ozwol account all assets, settings and configurations will be deleted at the end of the current period.
                    <hr style={{borderBottom: "1px solid #70707033", margin: "30px 0px"}} />
                    <ReferencePeriodTimeline
                      period={period}
                      user={item}
                      showCta={true}
                    />
                  </OzCard>
                : (item.status === "OPERATIVE" && item.accountToBeClosed === true) ? 
                  <OzCard
                    headerLeft={"Account closure requested"}
                    headerSeparator={true}
                    footerRight={<OzButton onClick={() => reopen()} variant={"chetwodeblue"}>Reopen</OzButton>}
                  >
                    You have requested to close your account and it will be closed at the end of the current period. If you have changed your mind, you can reopen your account here.
                    <hr style={{borderBottom: "1px solid #70707033", margin: "30px 0px"}} />
                    <ReferencePeriodTimeline
                      period={period}
                      user={item}
                      showCta={false}
                    />
                    <ErrorMessage>
                      {errorMessageOpenClose}
                    </ErrorMessage>
                  </OzCard>                      
                : item.status === "SUSPENDED" ? 
                  <OzCard
                    headerLeft={"Account suspended"}
                    headerSeparator={true}
                    // footerRight={<OzButton onClick={() => reopen()} variant={"chetwodeblue"}>Reopen</OzButton>}
                  >
                    Your account has been suspended, please <a href="mailto:sales@ozwol.com">contact us</a>.
                  </OzCard>  
                : null }
              </OzCol>
            : null}
          </OzRow>
        </HealthyWrapper>

      </OzContainer>
    </>
  )
}

export default Profile;
