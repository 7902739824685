import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Link} from 'react-router-dom';
import {OverlayContext} from './../../contexts/OverlayContext';
import styled from 'styled-components';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable, OzButton, OzUserTag, OzTag} from '@ozwol/webui';

import UsersService from './../../services/UsersService';

import InsiderUsersModalNew from './InsiderUsersModalNew';

import Filters from './../../components/Filters';
import Meta from './../../components/Meta';
import PageHeader from './../../components/PageHeader';
import NoResult from './../../components/NoResult';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from './../../components/HealthyWrapper';

import {formatError, getFormattedDateTime} from './../../helpers';

const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2){
    word-break: break-all;
    white-space: unset;
  }
`;

const InsiderUsersList = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);

  const [list, setList] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  const [fetchError, setFetchError] = useState(false);

  const DEFAULT_FILTERS = {
    "search": "",
    "active": true
  };
  const [filters, setFilters] = useState(
    localStorage.getItem("ozwolConsoleFilterInsiderUsers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))) ? 
      JSON.parse(localStorage.getItem("ozwolConsoleFilterInsiderUsers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1))))
    : 
      DEFAULT_FILTERS
    );

  const changeFilters = useCallback((name, value) => {
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const getList = useCallback(() => {
    UsersService.adminList({
      "search": filters.search.length > 2 ? filters.search : "",
      "active": filters.active
    }).then((response) => {
      localStorage.setItem("ozwolConsoleFilterInsiderUsers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)), JSON.stringify(filters));
      setList(response.result);
      setNextPage(response.nextPage);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [filters]); //page, filters

  const loadMore = useCallback(() => {
    UsersService.adminList({
      "search": filters.search.length > 2 ? filters.search : "",
      "active": filters.active,
      "page": nextPage
    }).then((response) => {
      setList([...list, ...response.result]);
      setNextPage(response.nextPage);
    }).catch((error) => {
      setFetchError(formatError(error));
    });

  }, [list, nextPage, filters]);

  useEffect(() => {
    getList();
  }, [getList, filters]); 


  return (
    <OzContainer size="extra">
      <Meta title={"Users - Insider"} />
      <PageHeader
        breadcrumbsIcon="person"
        breadcrumbsText={["Users"]}
        refScrollerPage={refScrollerPage}
        buttons={[
          {
            "icon": "add",
            "text": "Add",
            "onClick": () => addOverlay({
              reference: "form",
              content: <InsiderUsersModalNew />
            })
          }
        ]}
      />

      <OzRow>
        <OzCol>
          <HealthyWrapper error={fetchError} loading={!list}>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"Users"}
                />
              }
              headerSeparator={true}
            >
              <Filters
                onChange={(...args) => changeFilters(...args)}
                fields={
                  [
                    {
                      "label": "Search",
                      "name": "search",
                      "size": 4,
                      "type": "text",
                      "placeholder": null,
                      "value": filters.search
                    },{
                      "label": "Status",
                      "name": "active",
                      "size": 2,
                      "type": "select",
                      "placeholder": null,
                      "value": filters.active,
                      "options": [
                        {
                          "name": <i>All</i>,
                          "value": null
                        },
                        {
                          "name": "Enabled",
                          "value": true
                        },{
                          "name": "Disabled",
                          "value": false
                        }
                      ]
                    },{
                      "size": 2,
                      "type": "clear",
                      "callback": () => {
                        setFilters(DEFAULT_FILTERS);
                        // localStorage.removeItem("ozwolConsoleFilterInsiderUsers"+(process.env.REACT_APP_ENV.charAt(0).toUpperCase()+process.env.REACT_APP_ENV.substr(1)))
                      }
                    }
                  ]
                }
              />
              <hr/>

            
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "Company",
                        "User",
                        "Current Plan",
                        "Next Plan/Candidate Plan",
                        "Status",
                        "Contract Acceptance"
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          values: [
                            item.company,
                            <OzUserTag
                              name={item.givenName + " " + item.familyName}
                              email={item.email}
                            />,
                            item.currentPlan ? <OzTag variant={item.currentPlan.isCustom ? "chetwodeblue" : "silvertree"}>{item.currentPlan.name}</OzTag> : <OzTag variant="bittersweet">NO PLAN</OzTag>,
                            item.nextPlan ? 
                              <OzTag variant={item.nextPlan.isCustom ? "chetwodeblue" : "silvertree"}>{item.nextPlan.name}</OzTag> 
                            : 
                              (item.currentPlan.planType === "COMPLIMENTARY" ? 
                                (item.candidatePlan ? <OzTag variant={item.currentPlan.isCustom ? "chetwodeblue" : "silvertree"}>Candidate: {item.candidatePlan.name}</OzTag> : <OzTag variant="bittersweet">NO PLAN</OzTag>)
                              : 
                                <OzTag variant="bittersweet">NO PLAN</OzTag>
                              ),
                            <OzTag variant={
                              item.status === "OPERATIVE" && item.accountToBeClosed === false ? "silvertree" : 
                              item.status === "OPERATIVE" && item.accountToBeClosed === true ? "mustard" : 
                              item.status === "SUSPENDED" ? "bittersweet" : 
                              item.status === "CLOSED" ? "spanishgrey" : 
                              null
                            }>{item.status + (item.accountToBeClosed === true ? " (To Be Closed)" : "")}</OzTag>,
                            item.contractAcceptanceDatetime ? getFormattedDateTime(item.contractAcceptanceDatetime) : "-"
                          ],
                          link: ({children}) => (<Link to={"/insider/users/"+item.uuid}>{children}</Link>)
                        }))
                      }
                    />
                  </TableWrapper>
                  {nextPage ? 
                    <>
                      <br/><br/>
                      <center>
                        <OzButton variant="push-black" onClick={() => loadMore()}>Load more...</OzButton>
                      </center>
                    </>
                  : null}
                </>
              : <NoResult />}           
            </OzCard>          
          </HealthyWrapper>    
        </OzCol>
      </OzRow>
    </OzContainer>
  )
}

export default InsiderUsersList;
