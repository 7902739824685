import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {OverlayContext} from './../../contexts/OverlayContext';
// import styled from 'styled-components';

import {OzLoading, OzContainer, OzRow, OzCol, OzCard, OzInput, OzColorpicker, OzButton} from "@ozwol/webui";

import ProjectsService from '../../services/ProjectsService';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import ModalSuccess from '../../components/ModalSuccess';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ErrorMessage from '../../components/ErrorMessage';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {checkPermission, formatError, testValidField, clearObject} from '../../helpers';

const Settings = ({refScrollerPage}) => {
  const {addOverlay, replaceOverlay} = useContext(OverlayContext);
  const {projectUuid} = useParams();
  const [item, setItem] = useState(null);
  const [originalItem, setOriginalItem] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const getProject = useCallback(() => {
    ProjectsService.get(projectUuid).then((response) => {
      setItem(response);
      setOriginalItem(response);
    }).catch((error) => {
      setItem(null);
      setFetchError(formatError(error));
    });
  }, [projectUuid]);

  const updateProject = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    ProjectsService.update(projectUuid, clearObject(item, ["name","description","color"])).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Hotfolders fixed!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      })
      setOriginalItem(item);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [projectUuid, item, addOverlay]);

  useEffect(() => {
    getProject();
  }, [projectUuid, getProject]);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.name, true)){
        valid = false;
      }
      if(!testValidField(item.color, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem]);


  const fixHotfolders = useCallback(() => {
    addOverlay({
      reference: "fix-hotfolders",
      content: <OzLoading bgcolor={"#bfc4d7"} />
    })
    setErrorMessage(null);
    setDisabled(true);
    ProjectsService.fixHotfolders(projectUuid).then((response) => {
      replaceOverlay({
        reference: "fix-hotfolders",
        content: <ModalSuccess>Hotfolders fixed!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      })
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [projectUuid, addOverlay, replaceOverlay]);

  return (
    <OzContainer size="extra">
      {item ?
        <>
          <Meta title={item.name + " - Project settings"} />
          <PageHeader
            breadcrumbsIcon="work_update"
            breadcrumbsText={["Project settings", item ? item.name : "", item ? item.uuid : ""]}
            refScrollerPage={refScrollerPage}
          />
        </>
      : null }

      
      <HealthyWrapper error={fetchError} loading={!item}>
        {item ? 
          <OzRow>
            <OzCol>
              <OzCard
                headerLeft={
                  <CardHeaderTitle 
                    title={"Project settings"}
                  />
                }
                headerSeparator={true}
                footerRight={checkPermission("project", "update") ? <OzButton onClick={() => updateProject()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton> : null}
              >
                <OzRow>
                  <OzCol widthmd="6">
                    <OzInput
                      label="UUID"
                      width="100%"
                      value={item.uuid}
                      placeholder="UUID"
                      copyable={true}
                      disabled
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzColorpicker
                      label="Color"
                      width="100%"
                      value={item && item.color ? item.color : ""}
                      highlighted={item && originalItem && item.color !== originalItem.color}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.color = val;
                        setItem(tempItem);
                      }}
                      required
                      disabled={!checkPermission("project", "update")}
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Name"
                      width="100%"
                      value={item && item.name ? item.name : ""}
                      highlighted={item && originalItem && item.name !== originalItem.name}
                      placeholder="Name"
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.name = val;
                        setItem(tempItem);
                      }}
                      required
                      disabled={!checkPermission("project", "update")}
                    />
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput
                      label="Description"
                      width="100%"
                      value={item && item.description ? item.description : ""}
                      highlighted={item && originalItem && item.description !== originalItem.description}
                      placeholder="Description"
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.description = val;
                        setItem(tempItem);
                      }}
                      disabled={!checkPermission("project", "update")}
                    />
                  </OzCol>
                </OzRow>

                <ErrorMessage>{errorMessage}</ErrorMessage>
              </OzCard>
            </OzCol>
            {checkPermission("hotfolder", "fixAll") ? 
              <OzCol>
                <OzCard
                  headerLeft={
                    <CardHeaderTitle 
                      title={"Regenerate hotfolder"}
                    />
                  }
                  headerSeparator={true}
                  footerRight={<OzButton onClick={() => fixHotfolders()}>Regenerate hotfolder</OzButton>}
                >
                  <OzRow>
                    <OzCol widthmd="12">
                      Regenerates folders on your workspace in case of unintentional deletion.                    
                    </OzCol>
                  </OzRow>

                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </OzCard>
              </OzCol>
            : null}
          </OzRow>
        : null}
      </HealthyWrapper>
    </OzContainer>
  );
}

export default Settings;
