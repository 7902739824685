
import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';

import {OzButton, OzContainer, OzRow, OzCol, OzCard, OzTable, OzInput, OzCheckbox} from '@ozwol/webui';

import AccountService from './../../services/AccountService';

import Meta from './../../components/Meta';
import PageHeader from './../../components/PageHeader';
import CardHeaderTitle from './../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ErrorMessage from '../../components/ErrorMessage';
import PromptedButton from '../../components/PromptedButton';

import {checkPermission, formatError, clearObject} from '../../helpers';

const eventLabels = {
  "ACCOUNT_RECHARGED": "Account recharged",
  "CREDIT_CARD_EXPIRING": "Credit card expiring",
  "PAYMENT_FAILED": "Payment failed",
  "PERIOD_ENDING": "Period ending",
  "PERIOD_RENEWED": "Period renewed",
  "SEPA_NOTIFICATION": "SEPA notification",
}

const Table = styled.div`
  & tr:hover *{
    opacity: 1;
  }
`;
const TableButton = styled.div`
  text-align: right;
  opacity: 0;
`;
const NoLog = styled.div`
  text-align: center;
  padding: 50px;
  font-size: 12px;
  font-weight: 400;
`;


const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
`;

const Notifications = ({refScrollerPage}) => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [events, setEvents] = useState(null);


  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    AccountService.getUser().then((response) => {
      setUser(response);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
    AccountService.listNotificationEvents().then((response) => {
      setEvents(response);
    }).catch((error) => {
      setFetchError(formatError(error));
    });    
  }, []);

  const create = useCallback(() => {
    let tempUser = {...user};
    if(!tempUser.notifications){
      tempUser.notifications = {};
    }
    if(!Object.keys(tempUser.notifications).includes(email)){
      tempUser.notifications[email] = events;
    }

    setErrorMessage(null);
    AccountService.updateUser(clearObject(tempUser, ["notifications"])).then((response) => {
      setUser(response);
      setEmail("")
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });

  }, [events, email, user]);
  
  const edit = useCallback((email, event, value) => {
    let tempUser = {...user};
    if(!tempUser.notifications[email].includes(event)){
      tempUser.notifications[email].push(event);
    }else{
      tempUser.notifications[email] = tempUser.notifications[email].filter(v => v !== event);
    }

    setErrorMessage(null);
    AccountService.updateUser(clearObject(user, ["notifications"])).then((response) => {
      setUser(response);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [user]);


  const remove = useCallback((email) => {
    let tempUser = {...user};
    delete  tempUser.notifications[email];
    

    setErrorMessage(null);
    AccountService.updateUser(clearObject(user, ["notifications"])).then((response) => {
      setUser(response);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [user]);

  return (
    <OzContainer size="extra">
      <Meta title={"Notifications"} />        
      <PageHeader
        breadcrumbsIcon="notifications"
        breadcrumbsText={["Notifications", "Account"]}
        refScrollerPage={refScrollerPage}
      />


      <HealthyWrapper error={fetchError} loading={!user || !events}>
        <OzRow>
          <OzCol>
            <OzCard
              headerSeparator={true}
              collapsible={true}
              collapsed={false}
              headerLeft={<CardHeaderTitle title="Notifications" />}
            >
              {checkPermission("subNotifications", "update") ? 
                <>
                  <OzRow>
                    {/* <OzCol widthmd="3">
                      <OzInput label="User description" width="100%" value={fields[0] && fields[0]["alias"] ? fields[0]["alias"] : ""} onChange={(val) => editInput(0,"alias",val)}/>
                    </OzCol> */}
                    <OzCol widthmd="9">
                      <OzInput label="Email" width="100%" value={email} onChange={(val) => setEmail(val)} highlighted={email && email.length > 0}/>
                    </OzCol>
                    <OzCol widthmd="3">
                      <Label>&nbsp;</Label>
                      <OzButton
                        width="100%"
                        disabled={!email}
                        variant={"chetwodeblue"}
                        onClick={() => create()}
                      >Add new email</OzButton>
                    </OzCol>
                  </OzRow>
                  <br/>
                </>
              : null}
              {user && user.notifications && Object.keys(user.notifications).length > 0 ?
                <Table>
                  <OzTable
                    columns={
                      [
                        "Email"
                      ].concat(
                        events ? events.map((event) => eventLabels[event]) : []
                      ).concat([
                        ""
                      ])
                    }
                    values={Object.keys(user.notifications).map(email => ({
                      style: {
                      },
                      values: [
                        email
                      ].concat(
                        events ? events.map((event) => 
                          <OzCheckbox 
                            selected={user.notifications[email].includes(event)} 
                            onChange={(value) => edit(email, event, value)}
                            disabled={!checkPermission("subNotifications", "update")}
                          />,
                        ) : []
                      ).concat([                        
                        checkPermission("subNotifications", "update") ? 
                          <TableButton>
                            <PromptedButton 
                              buttonVariant="push-bittersweet" 
                              buttonSize={"small"} 
                              buttonText={<span className="material-symbols-outlined">delete</span>} 
                              prompt="DELETE"
                              buttonNoMinWidth={true}
                              onConfirm={() => remove(email)} 
                            /> 
                          </TableButton>
                        : null
                      ])
                    }))}
                  />
                </Table>
              :
                <NoLog>No notifications requested.</NoLog>
              }
            </OzCard>
          </OzCol>
{/*
          {projects.map((item, i) => (
            <OzCol key={i}>
              <OzCard
                headerSeparator={true}
                collapsible={true}
                collapsed={false}
                headerLeft={
                  <ProjectTag>
                    <ProjectTagColor color={PROJECT_COLORS[i%PROJECT_COLORS.length]}></ProjectTagColor>
                    <ProjectTagText>
                      <ProjectTagName>{item.name}</ProjectTagName>
                      <ProjectTagUuid>{item.uuid}</ProjectTagUuid>
                    </ProjectTagText>
                  </ProjectTag>
                }
              >
                <OzRow>
                  <OzCol widthmd="3">
                    <OzInput label="User description" width="100%" value={fields[item.uuid] && fields[item.uuid]["alias"] ? fields[item.uuid]["alias"] : ""} onChange={(val) => editInput(item.uuid,"alias",val)}/>
                  </OzCol>
                  <OzCol widthmd="6">
                    <OzInput label="Email" width="100%" value={fields[item.uuid] && fields[item.uuid]["email"] ? fields[item.uuid]["email"] : ""} onChange={(val) => editInput(item.uuid,"email",val)}/>
                  </OzCol>
                  <OzCol widthmd="3">
                    <Label>&nbsp;</Label>
                    <OzButton
                      width="100%"
                      size="big"
                      disabled={
                        !fields[item.uuid] ||
                        !fields[item.uuid]["alias"] ||
                        !fields[item.uuid]["email"] ||
                        fields[item.uuid]["alias"] === "" ||
                        fields[item.uuid]["email"] === ""
                      }
                      variant={
                        fields[item.uuid] &&
                        fields[item.uuid]["alias"] &&
                        fields[item.uuid]["alias"] !== "" &&
                        fields[item.uuid]["email"] &&
                        fields[item.uuid]["email"] !== "" ?
                          "chetwodeblue"
                        :
                          "disabled"
                      }
                      onClick={() => create(item.uuid)}
                    >Add new email</OzButton>
                  </OzCol>
                </OzRow>
                <br/>
                {projectsContacts.filter((subitem) => subitem.projectUuid === item.uuid).length > 0 ?
                  <Table>
                    <OzTable
                      columns={[
                        "Description",
                        "Email",
                        "Plan limit",
                        ""
                      ]}
                      values={projectsContacts.filter((subitem) => subitem.projectUuid === item.uuid)[0].contacts.sort((a,b)=>a.contactPk-b.contactPk).map(subitem => ({
                        style: {
                        },
                        values: [
                          subitem.alias,
                          subitem.email,
                          <OzCheckbox selected={subitem.events.includes("LIM")} onChange={(value) => edit(item.uuid, subitem.contactPk, "LIM", value)} />,
                          <TableButton>
                            <OzButton variant={"bittersweet-o"} onClick={() => remove(subitem.contactPk)}>Delete</OzButton>
                          </TableButton>
                        ]
                      }))}
                    />
                  </Table>
                : null }

              </OzCard>
            </OzCol>
          ))}
*/}
        </OzRow>
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      </HealthyWrapper>

    </OzContainer>
  )
}

export default Notifications;
