import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from './../../contexts/OverlayContext';
import {useParams} from 'react-router-dom';

import {OzContainer, OzRow, OzCol, OzCard, OzButton, OzInput, OzCheckbox, OzColorpicker, OzTag} from '@ozwol/webui';

import ProjectsService from '../../services/ProjectsService';

import InsiderProjectsModalNew from './InsiderProjectsModalNew';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ModalSuccess from '../../components/ModalSuccess';
import ErrorMessage from '../../components/ErrorMessage';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, clearObject, testValidField} from '../../helpers';


const InsiderProjectsSingle = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const {uuid} = useParams();
  const [item, setItem] = useState();
  const [originalItem, setOriginalItem] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  const [fetchError, setFetchError] = useState(false);


  const getItem = useCallback(() => {
    ProjectsService.adminGet(uuid).then((response) => {
      setItem(response);
      setOriginalItem(response);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [uuid]);

  const toggleActiveItem = useCallback(() => {
    ProjectsService.adminUpdate(item.uuid, {active: !item.active}).then((response) => {
      getItem();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item, getItem]);

  const deleteItem = useCallback(() => {
    ProjectsService.adminDelete(item.uuid).then((response) => {
      window.location.replace("/insider/projects");
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item]);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    ProjectsService.adminUpdate(uuid, clearObject(item, [
      "name",
      "color",
      "description"  ,
      "backup"  
    ])).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setOriginalItem(item);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [uuid, item, addOverlay]);

  const fixHotfolders = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    ProjectsService.adminFixHotfolders(uuid).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Hotfolders fixed!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [uuid, addOverlay]);

  useEffect(() => {
    getItem();
  }, [uuid, getItem]);


  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.name, true)){
        valid = false;
      }
      if(!testValidField(item.color, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem]);

  
  return (
    <>
      <Meta title={(item && item.name ? item.name : "") + " - Projects - Insider"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="business_center"
          breadcrumbsText={[
            <>{item && item.name ? item.name : ""} {item && !item.active ? <OzTag variant="bittersweet" size="small">DISABLED</OzTag> : null }</>
          ]}
          refScrollerPage={refScrollerPage}
          back={"/insider/projects"}
          buttons={[
            {
              "icon": "add",
              "text": "Add",
              "onClick": () => addOverlay({
                reference: "form",
                content: <InsiderProjectsModalNew />
              })
            },
            {
              "icon": "bookmark_manager",
              "text": "Fix Hotfolders",
              "onClick": () => fixHotfolders()
            },
            {
              "icon": item && !item.active ? "visibility" : "visibility_off",
              "text": item && !item.active ? "Enable" : "Disable",
              "onClick": () => toggleActiveItem()
            },
            {
              "icon": "delete",
              "text": "Delete",
              "prompt": "DELETE",
              "onClick": () => deleteItem()
            }
          ]}
        />

        <HealthyWrapper error={fetchError} loading={!item}>
          {item ?
            <OzRow>
              <OzCol>
                <OzCard
                  headerLeft={
                    <CardHeaderTitle title={"Project info"} />
                  }
                  headerSeparator={true}
                  footerRight={<OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
                >
                  <OzRow>
                    <OzCol widthmd="6">
                      <OzInput
                        label="UUID"
                        width="100%"
                        value={item.uuid}
                        placeholder="UUID"
                        copyable={true}
                        disabled
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Name"
                        width="100%"
                        value={item && item.name ? item.name : ""}
                        highlighted={item && originalItem && item.name !== originalItem.name}
                        placeholder="Name"
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.name = val;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzInput
                        label="Description"
                        width="100%"
                        value={item && item.description ? item.description : ""}
                        highlighted={item && originalItem && item.description !== originalItem.description}
                        placeholder="Description"
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.description = val;
                          setItem(tempItem);
                        }}
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzColorpicker
                        label="Color"
                        width="100%"
                        value={item && item.color ? item.color : ""}
                        highlighted={item && originalItem && item.color !== originalItem.color}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.color = val;
                          setItem(tempItem);
                        }}
                        required
                      />
                    </OzCol>
                    <OzCol widthmd="6">
                      <OzCheckbox
                        label="Backup"
                        selected={item.backup}
                        highlighted={item && originalItem && item.backup !== originalItem.backup}
                        onChange={(val) => {
                          let tempItem = {...item};
                          tempItem.backup = val;
                          setItem(tempItem);
                        }}
                      />
                    </OzCol>
                  </OzRow>
                  <ErrorMessage>
                    {errorMessage}
                  </ErrorMessage>
                </OzCard>
              </OzCol>
            </OzRow>
          : null}
        </HealthyWrapper>

      </OzContainer>
    </>
  )
}

export default InsiderProjectsSingle;
