//TODO pulizia file
import React, { useState, useContext, useEffect} from 'react';

import {OverlayContext} from '../../contexts/OverlayContext';

import {OzContainer, OzButton, OzCol, OzRow, OzInput, OzCard, OzSelect} from "@ozwol/webui";

import UsersService from '../../services/UsersService';
import PlansService from '../../services/PlansService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, testValidField} from '../../helpers';



const InsiderUsersModalNew = () => {
  const {removeOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState();
  const [planUuid, setPlanUuid] = useState();

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const [searchPlans, setSearchPlans] = useState("");
  const [listPlans, setListPlans] = useState([]);

  const save = () => {
    UsersService.adminCreate(planUuid, item).then((response) => {
      window.location.replace("/insider/users/"+response.uuid);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });

  };

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item){
      if(!testValidField(planUuid, true)){
        valid = false;
      }
      if(!testValidField(item.givenName, true)){
        valid = false;
      }
      if(!testValidField(item.familyName, true)){
        valid = false;
      }
      if(!testValidField(item.company, true)){
        valid = false;
      }
      if(!testValidField(item.email, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, planUuid]);

  useEffect(() => {
    PlansService.adminList({
      "plan_type": "COMPLIMENTARY",
      "search": searchPlans.length > 2 ? searchPlans : "",
    }).then((response) => {
      setListPlans(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [searchPlans]);

  return (
    <OzContainer>
      <HealthyWrapper error={fetchError}>
      
        <OzCard
          headerLeft={
            <CardHeaderTitle title={"Add user"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("form")}>Back</OzButton>}
          footerRight={<OzButton onClick={() => save()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
        >

          <OzRow>
            <OzCol widthmd="12">
              <OzSelect
                label="Complimentary plan"
                width="100%"
                placeholder="Complimentary plan"
                selected={planUuid ? planUuid : null}
                highlighted={planUuid && planUuid.length > 0}
                values={listPlans.map((item) => ({
                  "name": item.name,
                  "value": item.uuid,
                }))}
                onChange={(val) => {
                  setPlanUuid(val);
                }}
                maxHeight={"200px"}
                searchable={true}
                search={searchPlans}
                onSearch={(val) => setSearchPlans(val)}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="Given name"
                width="100%"
                value={item && item.givenName ? item.givenName : ""}
                highlighted={item && item.givenName && item.givenName.length > 0}
                placeholder="Given name"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.givenName = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="Family name"
                width="100%"
                value={item && item.familyName ? item.familyName : ""}
                highlighted={item && item.familyName && item.familyName.length > 0}
                placeholder="Family name"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.familyName = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="Company"
                width="100%"
                value={item && item.company ? item.company : ""}
                highlighted={item && item.company && item.company.length > 0}
                placeholder="Company"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.company = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            <OzCol widthmd="6">
              <OzInput
                label="Account email"
                width="100%"
                value={item && item.email ? item.email : ""}
                highlighted={item && item.email && item.email.length > 0}
                placeholder="Account email"
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.email = val;
                  setItem(tempItem);
                }}
                required
              />
            </OzCol>
            
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default InsiderUsersModalNew;
