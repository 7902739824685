//TODO pulizia file
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import {IbanElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {OverlayContext} from './../contexts/OverlayContext';

import {OzContainer, OzButton, OzCol, OzRow, OzInput, OzCard, BITTERSWEET, CHETWODEBLUE} from "@ozwol/webui";

import AccountService from './../services/AccountService';

import ModalNewPayment from './ModalNewPayment';
import ModalActivate from './ModalActivate';

import CardHeaderTitle from './../components/CardHeaderTitle';
import ErrorMessage from '../components/ErrorMessage';


const Label = styled.div`
margin-top: 20px;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: 300;
`;

const FakeInput = styled.div`
  background-color: #fff;
  color: #00141f;
  padding: 0px 10px;
  border: 1px solid #cbcbcb;
  height: 34px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 1px;
  font-size: 12px;
  outline: currentcolor none medium;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  box-shadow: #687fe261 0px 1px 2px inset;

  & > *{
    margin-top: 8px;
  }
`;

const Note = styled.div`
  opacity: 0.5;
  margin-top: 5px;
  font-size: 11px;
`;


const NotificationBlock = styled.div`
  border-radius: 9px;
  background: ${CHETWODEBLUE}13;
  padding: 20px;
  height: 500px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
`;
const NotificationBlockContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const NotificationBlockContentIcon = styled.div`
  width: 130px;
  height: 130px;
  background: #fff;
  border-radius: 130px;
  display: inline-block;
  margin: 0 auto;
  border: 20px solid #F9FAFD;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > *{
    color: ${CHETWODEBLUE};
    font-size: 90px;
  }
`;
const Spinner = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 90px;
  display: inline-block;
  border: 8px solid ${CHETWODEBLUE};
  box-sizing: border-box;
  animation: spin 1s linear infinite;
  position: relative;

  &::after{
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    background: #ffffff;
    border-radius: 0 0 100px 0;
    -moz-border-radius: 0 0 100px 0;
    -webkit-border-radius: 0 0 100px 0;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
`;
const NotificationBlockContentText = styled.div`
  color: ${CHETWODEBLUE};
  font-weight: bold;
  font-size: 16px;
  margin: 20px auto 0px;
  max-width: 70%;
`;
const NotificationBlockButton = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const STATUS_START = 0;
const STATUS_LOADING = 1;
const STATUS_DONE = 2;
const STATUS_ERROR = 3;

export default function ModalNewCard({ isActivation = false, onSubmit, onCancel, onDone, isDone }) {
  const {replaceOverlay, removeOverlay} = useContext(OverlayContext);

  const [isLoading, setLoading] = useState(false);
  const [holderName, setHolderName] = useState('');
  const [ibanStatus, setIbanStatus] = useState(false);

  const [status, setStatus] = useState(STATUS_START);
  const stripe = useStripe();
  const elements = useElements();

  if(isDone && status!==STATUS_DONE){
    setStatus(STATUS_DONE);
  }

  const checkPlanActivation = (newPlanUuid) => {
    AccountService.getUser().then((res) => {
      if(newPlanUuid !== res.currentPlan.uuid){
        setTimeout(() => {
          checkPlanActivation(newPlanUuid);
        },1000);
      }else{
        window.location.replace("/");
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleSubmit = async (event) => {
    setStatus(STATUS_LOADING);
    await setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    
    const sepaElement = elements.getElement(IbanElement);
    AccountService.getUser().then((response) => {

      AccountService.createPaymentMethodSepaIntent().then((response1) => {
        stripe.confirmSepaDebitSetup(response1, {
          payment_method: {
            sepa_debit: sepaElement,
            billing_details: {
              name: holderName,
              email: response.email,
            },
          }
        }).then((response2) => {
          if(isActivation){
            AccountService.setDefaultPaymentMethod(response2.setupIntent.payment_method).then((response3) => {
              AccountService.getUser().then((response4) => {
                AccountService.initPaidPlan(response4.candidatePlan.uuid).then((response5) => {
                  // setUser(response)
                  // window.location.replace("/");
                  checkPlanActivation(response4.candidatePlan.uuid);
                }).catch((error) => {
                  setStatus(STATUS_ERROR);
                });
              }).catch((error) => {
                setStatus(STATUS_ERROR);
              });
            }).catch((error) => {
              setStatus(STATUS_ERROR);
            });
          }
          setStatus(STATUS_DONE);
        });
        
      }).catch((error) => {
        setStatus(STATUS_ERROR);
      });
    }).catch((error) => {
      setStatus(STATUS_ERROR);
    });

    

    // AccountService.createPaymentMethodCard({
    //   source: token.id
    // }).then((response) => {
    //   setStatus(STATUS_DONE);
    // }).catch((error) => {
    //   setStatus(STATUS_ERROR);
    // });

  };

  let disabled = isLoading || holderName.trim() === '' || !ibanStatus;
  return (
    <OzContainer size="small">
      <div style={{"display": status === STATUS_START ? "block" : "none"}}>
        <form>
          <OzCard
            headerLeft={
              <CardHeaderTitle title={"Add Credit Card"} />
            }
            headerSeparator={true}
            footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => 
              replaceOverlay({
                reference: "payment-method",
                content: isActivation ? <ModalActivate /> : <ModalNewPayment />
              }, "payment-method")
            }>Back</OzButton>}
            footerRight={<OzButton onClick={() => handleSubmit()} type="submit" variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
          >

            <OzRow>
              <OzCol widthmd="12">
                <OzInput
                  label="Owner"
                  width="100%"
                  value={holderName}
                  placeholder="Owner"
                  onChange={(val) => setHolderName(val)}
                  required
                />
              </OzCol>
              <OzCol widthmd="12">
                <Label>Iban</Label>
                <FakeInput>
                  <IbanElement
                    label="Iban"
                    onChange={({ complete }) => setIbanStatus(complete)}
                    options={{
                      supportedCountries: ["SEPA"],
                      placeholderCountry: 'IT',
                      style: {
                        base: {
                          color: "#00141f",
                          fontSize: "14px",
                          ':-webkit-autofill': {
                            color: '#495057',
                          },
                          '::placeholder': {
                            color: '#dedede'
                          },
                        },
                        invalid: {
                          iconColor: '#fff',
                          color: BITTERSWEET,
                        },
                      }
                    }}
                  />
                </FakeInput>
              </OzCol>

              <OzCol widthmd="12">
                <br/>
                <Note>We use Stripe as our transaction processor to accept payments. Ozwol does not keep any data relating to payment methods. For more informations: <a target="_blank" rel="noreferrer" href="https://stripe.com/">Stripe.com</a></Note>
                <br/>
              </OzCol>
            </OzRow>
          </OzCard>
        </form>
      </div>
      { status === STATUS_LOADING ?
        <OzCard>
          <NotificationBlock>
            <NotificationBlockContent>
              <NotificationBlockContentIcon>
                <Spinner />
              </NotificationBlockContentIcon>
              <NotificationBlockContentText>A few seconds...</NotificationBlockContentText>
            </NotificationBlockContent>
            <NotificationBlockButton>
              <OzButton variant={"chetwodeblue"} display={"inline"} disabled={true}>Finish</OzButton>
            </NotificationBlockButton>
          </NotificationBlock>
        </OzCard>
      : status === STATUS_DONE && !isActivation?
        <OzCard>
          <NotificationBlock>
            <NotificationBlockContent>
              <NotificationBlockContentIcon>
                <span className="material-symbols-outlined">account_balance</span>
              </NotificationBlockContentIcon>
              <NotificationBlockContentText>The new SEPA has been successfully added!<br/><small>Thank you for yout business with us.</small></NotificationBlockContentText>
            </NotificationBlockContent>
            <NotificationBlockButton>
              <OzButton type="button" display={"inline"} variant={"chetwodeblue"} onClick={() => removeOverlay("payment-method")}>Finish</OzButton>
            </NotificationBlockButton>
          </NotificationBlock>
        </OzCard>
      : status === STATUS_DONE && isActivation?
        <OzCard>
          <NotificationBlock>
            <NotificationBlockContent>
              <NotificationBlockContentIcon>
                <Spinner />
              </NotificationBlockContentIcon>
              <NotificationBlockContentText>A few seconds...</NotificationBlockContentText>
            </NotificationBlockContent>
            <NotificationBlockButton>
              <OzButton variant={"chetwodeblue"} display={"inline"} disabled={true}>Finish</OzButton>
            </NotificationBlockButton>
          </NotificationBlock>
        </OzCard>      
      : status === STATUS_ERROR ?
        <OzCard>
          <NotificationBlock>
            <NotificationBlockContent>
              <NotificationBlockContentIcon>
                <span className="material-symbols-outlined">account_balance</span>
              </NotificationBlockContentIcon>
              <NotificationBlockContentText>
                <ErrorMessage>ERROR</ErrorMessage>
              </NotificationBlockContentText>
            </NotificationBlockContent>
            <NotificationBlockButton>
              <OzButton type="button" display={"inline"} variant={"chetwodeblue"} onClick={() => removeOverlay("payment-method")}>Finish</OzButton>
            </NotificationBlockButton>
          </NotificationBlock>
        </OzCard>
      : null }
    </OzContainer>
  );

}
