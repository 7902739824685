import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import {useParams} from 'react-router-dom';

import {OzContainer, OzRow, OzCol, OzCard, OzButton, OzInput, OzCheckbox, OzTag, OzLoading, OzProjectTag} from '@ozwol/webui';

import HotfoldersService from '../../services/HotfoldersService';
import ProjectsService from '../../services/ProjectsService';

import InsiderHotfoldersModalNew from './InsiderHotfoldersModalNew';
import InsiderHotfoldersModalDuplicate from './InsiderHotfoldersModalDuplicate';
import InsiderHotfoldersActionsSingle from './InsiderHotfoldersActionsSingle';
import InsiderHotfoldersActionsModalNew from './InsiderHotfoldersActionsModalNew';

import Meta from '../../components/Meta';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import ModalSuccess from '../../components/ModalSuccess';
import ErrorMessage from '../../components/ErrorMessage';
import NoResult from '../../components/NoResult';

import {OVERLAY_TIMEOUT_SUCCESS} from '../../config';
import {formatError, clearObject, testValidField} from '../../helpers';


const InsiderHotfoldersSingle = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const {hotfolderUuid} = useParams();
  const [item, setItem] = useState();
  const [originalItem, setOriginalItem] = useState(null);
  const [project, setProject] = useState();
  const [listActions, setListActions] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  
  const [fetchError, setFetchError] = useState(false);


  const getListActions = useCallback(() => {
    HotfoldersService.adminListActions({"hotfolderUuid": hotfolderUuid}).then((response) => {
      setListActions(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [hotfolderUuid]);
  const getItem = useCallback(() => {
    HotfoldersService.adminGet(hotfolderUuid).then((response) => {
      setItem(response);
      setOriginalItem(response);

      ProjectsService.adminGet(response.projectUuid).then((response) => {
        setProject(response);
      }).catch((error) => {
        setFetchError(formatError(error));
      });

    }).catch((error) => {
      setFetchError(formatError(error));
    });
    getListActions();
  }, [hotfolderUuid, getListActions]);

  const toggleActiveItem = useCallback(() => {
    HotfoldersService.adminUpdate(item.uuid, {active: !item.active}).then((response) => {
      getItem();
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item, getItem]);

  const deleteItem = useCallback(() => {
    HotfoldersService.adminDelete(item.uuid).then((response) => {
      window.location.replace("/insider/hotfolders");
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [item]);

  const updateItem = useCallback(() => {
    setErrorMessage(null);
    setDisabled(true);
    HotfoldersService.adminUpdate(hotfolderUuid, clearObject(item, [
      "path",
      "description",
      "maxHops",
      "onCompletionDelete"
    ])).then((response) => {
      addOverlay({
        reference: "success",
        content: <ModalSuccess>Your data has been successfully saved!</ModalSuccess>,
        timeout: OVERLAY_TIMEOUT_SUCCESS
      });
      setOriginalItem(item);
    }).catch((error) => {
      setErrorMessage(formatError(error));
    });
  }, [hotfolderUuid, item, addOverlay]);

  useEffect(() => {
    getItem();
  }, [hotfolderUuid, getItem]);


  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.path, true)){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, originalItem]);



  
  return (
    <>
      <Meta title={(item && item.path ? item.path.split("/")[item.path.split("/").length - 2] : "") + " - Hotfolders - Insider"} />
      <OzContainer size="extra">
        <PageHeader
          breadcrumbsIcon="folder"
          breadcrumbsText={[
            <>{item && item.path ? item.path.split("/")[item.path.split("/").length - 2] : ""} {item && !item.active ? <OzTag variant="bittersweet" size="small">DISABLED</OzTag> : null }</>
          ]}
          refScrollerPage={refScrollerPage}
          back={"/insider/hotfolders"}
          buttons={item ? [
            {
              "icon": "add",
              "text": "Add",
              "onClick": () => addOverlay({
                reference: "form",
                content: <InsiderHotfoldersModalNew ownerUuid={item.ownerUuid} />
              })
            },
            {
              "icon": "content_copy",
              "text": "Duplicate",
              "onClick": () => addOverlay({
                reference: "form",
                content: <InsiderHotfoldersModalDuplicate source={item} />
              })
            },
            {
              "icon": item && !item.active ? "visibility" : "visibility_off",
              "text": item && !item.active ? "Enable" : "Disable",
              "onClick": () => toggleActiveItem()
            },
            {
              "icon": "delete",
              "text": "Delete",
              "prompt": "DELETE",
              "onClick": () => deleteItem()
            }
          ] : []}
        />

            
        <HealthyWrapper error={fetchError} loading={!item}>
          {item ?
            <>
              <OzRow>
                <OzCol>
                  <OzCard
                    headerLeft={
                      <CardHeaderTitle title={"Hotfolder info"} />
                    }
                    headerRight={
                      project ?
                        <OzProjectTag
                          name={project.name}
                          color={project.color}
                          uuid={project.uuid}
                          variant={"chetwodeblue"}
                          direction={"horizontal"}
                        />  
                      : null
                    }
                    headerSeparator={true}
                    footerRight={<OzButton onClick={() => updateItem()} variant={"chetwodeblue"} disabled={disabled}>Save</OzButton>}
                  >
                    <OzRow>
                      <OzCol widthmd="12">
                        <OzInput
                          label="UUID"
                          width="100%"
                          value={item.uuid}
                          placeholder="UUID"
                          copyable={true}
                          disabled
                        />
                      </OzCol>
                      <OzCol widthmd="12">
                        <OzInput
                          label="Path"
                          width="100%"
                          value={item.path}
                          placeholder="Path"
                          disabled
                        />
                      </OzCol>
                      <OzCol widthmd="9">
                        <OzInput
                          label="Description"
                          width="100%"
                          value={item.description}
                          highlighted={item && originalItem && item.description !== originalItem.description}
                          placeholder="Description"
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.description = val;
                            setItem(tempItem);
                          }}
                        />
                      </OzCol>
                      <OzCol widthmd="3">
                        <OzInput
                          label="Max hops"
                          width="100%"
                          value={item.maxHops}
                          highlighted={item && originalItem && item.maxHops !== originalItem.maxHops}
                          placeholder="Max hops"
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.maxHops = val;
                            setItem(tempItem);
                          }}
                        />
                      </OzCol>
                      <OzCol widthmd="12">
                        <OzCheckbox
                          label="On completion delete"
                          selected={item.onCompletionDelete}
                          highlighted={item && originalItem && item.onCompletionDelete !== originalItem.onCompletionDelete}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.onCompletionDelete = val;
                            setItem(tempItem);
                          }}
                        />
                      </OzCol>
                    </OzRow>
                    <ErrorMessage>
                      {errorMessage}
                    </ErrorMessage>
                  </OzCard>
                </OzCol>
              </OzRow>

              <br/><br/>


              <OzRow>
                {listActions === null ? 
                  <OzCol>
                    <OzLoading />
                  </OzCol>
                : listActions.length > 0 ? 
                  listActions.map((item, i) =>
                    <OzCol key={i}>
                      <InsiderHotfoldersActionsSingle
                        listItems={listActions}
                        itemUuid={item.uuid}
                        onSuccess={() => getListActions()}
                      /> 
                    </OzCol>
                  )
                :
                  <OzCol>
                    <NoResult>There are no scripts.</NoResult>
                  </OzCol>
                }
                <OzCol>
                  <center>
                    <OzButton onClick={() => addOverlay({
                      reference: "form",
                      content: <InsiderHotfoldersActionsModalNew ownerUuid={item.ownerUuid} path={item.path} hotfolderUuid={hotfolderUuid} />
                    })} variant={"silvertree"}>Add action</OzButton>
                  </center>
                </OzCol>
              </OzRow>
            </>
          : null}
        </HealthyWrapper>

      </OzContainer>
    </>
  )
}

export default InsiderHotfoldersSingle;
