import React, { useState, useContext, useEffect} from 'react';

import {OverlayContext} from '../../contexts/OverlayContext';

import {OzContainer, OzButton, OzSelect, OzCol, OzRow, OzInput, OzCard, OzProjectTag, OzTable} from "@ozwol/webui";

import HotfoldersService from '../../services/HotfoldersService';
import ProjectsService from '../../services/ProjectsService';

import CardHeaderTitle from '../../components/CardHeaderTitle';
import ErrorMessage from '../../components/ErrorMessage';
import WarningMessage from '../../components/WarningMessage';
import HealthyWrapper from '../../components/HealthyWrapper';

import {formatError, testValidField} from '../../helpers';



const InsiderHotfoldersModalPublish = ({sourceItems}) => {
  const {removeOverlay} = useContext(OverlayContext);
  const [item, setItem] = useState({
    path: sourceItems && sourceItems.length === 1 && sourceItems[0].path ? sourceItems[0].path.replace("HOTFOLDERS/","") : "INPUT/",
    description: sourceItems && sourceItems.length === 1 && sourceItems[0].description ? sourceItems[0].description : "",
    onCompletionDelete: true
  });
  const [originalItem] = useState({
    path: sourceItems && sourceItems.length === 1 && sourceItems[0].path ? sourceItems[0].path.replace("HOTFOLDERS/","") : "INPUT/",
    description: sourceItems && sourceItems.length === 1 && sourceItems[0].description ? sourceItems[0].description : "",
    onCompletionDelete: true
  });

  const [searchProjects, setSearchProjects] = useState("");
  const [listProjects, setListProjects] = useState([]);

  const [disabled, setDisabled] = useState(true);
  // const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  const publish = () => {
    setErrorMessage(null);
    if(sourceItems.length === 1){
      let salvageableItem = {};
      salvageableItem.projectUuid = item.projectUuid;
      salvageableItem.path = "HOTFOLDERS/" + item.path;
      salvageableItem.description = item.description;
      HotfoldersService.adminClone(sourceItems[0].uuid, salvageableItem).then((response) => {
        window.location.replace("/insider/hotfolders/"+response.uuid);
      }).catch((error) => {
        setErrorMessage(formatError(error));
      });
      
    }else{

      Promise.all(sourceItems.map((sourceItem) => {
        let salvageableItem = {};
        salvageableItem.projectUuid = item.projectUuid;
        salvageableItem.path = sourceItem.path;
        salvageableItem.description = sourceItem.description;
        return new Promise((resolve, reject) => { 
          HotfoldersService.adminClone(sourceItem.uuid, salvageableItem).then((response) => {
            // window.location.replace("/insider/hotfolders/"+response.uuid);
            resolve();
          }).catch((error) => {
            if(error.response.data.detail){
              setErrorMessage((errorMessage ? (errorMessage + " | ") : "") + error.response.data.detail);
            }else{
              setErrorMessage((errorMessage ? (errorMessage + " | ") : "") + "There was an error when saving data.");
            }          
            reject(error);
          });
        });
      })).then((responses) => {
        window.location.replace("/insider/hotfolders/");
      }).catch((response) => {
        setErrorMessage(response.message)
      });

      
      // HotfoldersService.clone({
      //   "owner_uuid": sourceItem.owner_uuid,
      //   "source_hotfolder_uuid": sourceItem.uuid,
      //   "destination_project_uuid": sourceItem.project_uuid,
      //   "destination_hotfolder_path": item.path
      // }).then((response) => {
      //   HotfoldersService.update(response.result.uuid, {
      //     "owner_uuid": sourceItem.owner_uuid,
      //     "description": item.description,
      //   }).then((response2) => {
      //     fnSuccess(response.result.uuid);
      //   });
      // });
    }  
  };

  useEffect(() => {
    ProjectsService.adminList({
      "search": searchProjects.length > 2 ? searchProjects : "",
    }).then((response) => {
      setListProjects(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, [searchProjects]);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(item && item !== originalItem){
      if(!testValidField(item.projectUuid, true)){
        valid = false;
      }
      if(sourceItems.length === 1){
        if(!testValidField(item.path, true)){
          valid = false;
        }
      }
      if(sourceItems.length === 0){
        valid = false;
      }
    }else{
      valid = false;
    }
    setDisabled(!valid);
  }, [item, sourceItems, originalItem]);

  return (
    <OzContainer>
      
      <HealthyWrapper error={fetchError}>
        <OzCard
          headerLeft={
            <CardHeaderTitle title={sourceItems.length > 1 ? "Publish hotfolders" : "Publish hotfolder"} />
          }
          headerSeparator={true}
          footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("form")}>Back</OzButton>}
          footerRight={<OzButton onClick={() => publish()} variant={"chetwodeblue"} disabled={disabled}>Publish</OzButton>}
        >

          <OzRow>
            <OzCol widthmd="12">
              <OzSelect
                label="Destination project"
                width="100%"
                selected={item && item.projectUuid ? item.projectUuid : null}
                highlighted={item && originalItem && item.projectUuid !== originalItem.projectUuid}
                values={listProjects.map((item) => ({
                  "name": <OzProjectTag
                      name={item.name}
                      color={item.color}
                      uuid={item.uuid}
                      direction={"horizontal"}
                    />,
                  "value": item.uuid,
                }))}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.projectUuid = val;
                  setItem(tempItem);               
                }}
                maxHeight={"200px"}
                searchable={true}
                search={searchProjects}
                onSearch={(val) => setSearchProjects(val)}
                required
              />
            </OzCol>


            <OzCol widthmd="12">
              <OzTable
                columns={[
                  "Original path"
                ]}
                values={
                  sourceItems.map(item => ({
                    values: [
                      item.path
                    ]
                  }))
                }
              />
            </OzCol>

            {sourceItems.length === 1 ? 
              <>
                <OzCol widthmd="12">
                  <OzInput
                    label="Path"
                    preLabel={"HOTFOLDERS/"}
                    width="100%"
                    value={item && item.path ? item.path : ""}
                    highlighted={item && originalItem && item.path !== originalItem.path}
                    placeholder="Path"
                    onChange={(val) => {
                      let tempItem = {...item};
                      tempItem.path = val.replaceAll(" ","_");
                      tempItem.description = val.replace("INPUT/","");
                      setItem(tempItem);
                    }}
                  />              
                  <WarningMessage>{!item.path.startsWith("INPUT/") ? "Warning: without INPUT/ some regexes may not work correctly." : null }</WarningMessage>              
                </OzCol>
                <OzCol widthmd="12">
                  <OzInput
                    label="Description"
                    width="100%"
                    value={item && item.description ? item.description : ""}
                    highlighted={item && originalItem && item.description !== originalItem.description}
                    placeholder="Description"
                    onChange={(val) => {
                      let tempItem = {...item};
                      tempItem.description = val;
                      setItem(tempItem);
                    }}
                    required
                  />
                </OzCol>
              </>
            : null }
            
          </OzRow>
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
          
        
        </OzCard>
      </HealthyWrapper>
    </OzContainer>
  );

}
export default InsiderHotfoldersModalPublish;
