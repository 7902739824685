import React, {useState, useEffect, useCallback, useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import styled from 'styled-components';
import {CDN} from './../../config';

import {BITTERSWEET, OzContainer, OzRow, OzCol, OzCard, OzTable, OzProjectTag, OzButton, OzInput, OzTag} from "@ozwol/webui";

import ApiKeysService from '../../services/ApiKeysService';
import ProjectsService from './../../services/ProjectsService';

import Meta from '../../components/Meta';
// import ModalError from '../../components/ModalError';
import PageHeader from '../../components/PageHeader';
import CardHeaderTitle from '../../components/CardHeaderTitle';
import HealthyWrapper from '../../components/HealthyWrapper';
import PromptedButton from '../../components/PromptedButton';

import ApiKeysModalNew from './ApiKeysModalNew';
import ApiKeysModalEdit from './ApiKeysModalEdit';

// import {OVERLAY_TIMEOUT_ERROR} from '../../config';
import {formatError} from '../../helpers';


const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(1),
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
`;
const NoResult = styled.div`
  text-align: center;
  font-weight: bold;

  & > img{
  width: 40%;

`;


const ApiKeys = ({refScrollerPage}) => {
  const {addOverlay} = useContext(OverlayContext);
  const [list, setList] = useState(null);
  const [listProjects, setListProjects] = useState(null);

  const [fetchError, setFetchError] = useState(false);



  const getList = useCallback(() => {
    ApiKeysService.list().then((response) => {
      setList(response.result);
    }).catch((error) => {
      setFetchError(formatError(error));
    });
  }, []);

  const deleteItem = useCallback((item) => {
    setList(null);
    ApiKeysService.delete(item.uuid).then((response) => {
      getList();
    }).catch((error) => {
      setList(null);
      setFetchError(formatError(error));
    });
  }, [getList]);

  useEffect(() => {
    getList();
    ProjectsService.list().then((response) => {
      setListProjects(response.result);
    });
  }, [getList]); 


  return (
    <OzContainer size="extra">
      <Meta title={"API Keys"} />
      <PageHeader
        breadcrumbsIcon="key"
        breadcrumbsText={["Overview", "API Keys"]}
        refScrollerPage={refScrollerPage}
      />
  
      
      <HealthyWrapper error={fetchError} loading={!list || !listProjects}>
        <OzRow>
          <OzCol>
            <OzCard
              headerLeft={
                <CardHeaderTitle 
                  title={"API Keys"}
                />
              }
              headerSeparator={true}
              footerRight={<OzButton
                onClick={() => addOverlay({
                  reference: "form",
                  content: <ApiKeysModalNew projects={listProjects} onSuccess={() => getList()} />
                })} 
                variant={"chetwodeblue"}
              >Add</OzButton>}
            >
              {list && list.length > 0 ?
                <>
                  <TableWrapper>
                    <OzTable
                      columns={[
                        "Description",
                        "Key",
                        "Active",
                        "Projects",
                        ""
                      ]}
                      values={
                        list.map(item => ({
                          style: !item.active ? {
                            background: BITTERSWEET+"33",
                            backgroundHover: BITTERSWEET+"55"
                          } : {},
                          values: [
                            item.description,
                            <OzInput width="100%" copyable type="password" toggleVisibility disabled value={item.key} />,
                            <OzTag variant={item.active ? "silvertree" : "bittersweet"}>{item.active ? "Yes" : "No"}</OzTag>,
                            item && item.projectsUuid && item.projectsUuid.length > 0 ? 
                              (item.projectsUuid[0] === "*" ? 
                                "All" 
                              : 
                                item.projectsUuid.map(projectUuid => 
                                  listProjects && listProjects.filter(val => val.uuid === projectUuid).length > 0 ?
                                    listProjects.filter(val => val.uuid === projectUuid).slice(0,1).map(project =>
                                      <OzProjectTag
                                        direction="horizontal"
                                        key={project.uuid}
                                        name={project.name}
                                        uuid={project.uuid}
                                        color={project.color}
                                      />
                                    )
                                  : "-"
                                )
                              ) 
                            : "-",
                            <>
                              <OzButton 
                                onClick={() => addOverlay({
                                  reference: "form",
                                  content: <ApiKeysModalEdit uuid={item.uuid} projects={listProjects} onSuccess={() => getList()} />
                                })}
                                noMinWidth={true}
                                variant="push-black"
                                size="small"
                              ><span className="material-symbols-outlined">edit</span></OzButton>
                              &nbsp;
                              <PromptedButton 
                                buttonVariant="push-bittersweet" 
                                buttonSize={"small"} 
                                buttonText={<span className="material-symbols-outlined">delete</span>} 
                                prompt="DELETE"
                                buttonNoMinWidth={true}
                                onConfirm={() => deleteItem(item)} 
                              /> 
                              
                            </>
                          ]
                        }))
                      }
                    />
                  </TableWrapper>
                </>
              : 
                <NoResult>
                  <img src={CDN + "console-assets/no-generic.svg"} alt="no api keys" /><br/><br/>
                  There are no active API keys.<br/>
                  Click Add to get started.
                </NoResult>
              }   

            </OzCard>
          </OzCol>
        </OzRow>
      </HealthyWrapper>
    </OzContainer>
  );
}

export default ApiKeys;
