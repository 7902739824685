import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {OverlayContext} from '../contexts/OverlayContext';
import {QRCodeSVG} from 'qrcode.react';

import {OzButton, OzCard, OzRow, OzCol, CHETWODEBLUE, WHITESMOKE} from '@ozwol/webui';

import CognitoService from '../services/CognitoService';

import ModalMFA3 from './ModalMFA3';
import MfaWizard from './../pages/account/common/MfaWizard';

import CardHeaderTitle from '../components/CardHeaderTitle';
// import ErrorMessage from '../components/ErrorMessage';

// import {formatError} from '../helpers';

const Container = styled.div`
  width: 450px;
`;
const Qr = styled.div`
  text-align: right;
`;
const Cta = styled.div`
  color: ${CHETWODEBLUE};
  font-size: 10px;
  cursor: pointer;
`;
const SecretCode = styled.div`
  font-size: 10px;
  word-break: break-all;
  background-color: ${WHITESMOKE};
  padding: 5px;
`;

const ModalMFA2 = ({user, onSuccess}) => {
  const {replaceOverlay, removeOverlay} = useContext(OverlayContext);
  const [secretCode, setSecretCode] = useState(null);
  const [showSecretCode, setShowSecretCode] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    // setErrorMessage(null);
    CognitoService.setMFA().then((response) => {
      setSecretCode(response.SecretCode);
    }).catch((error) => {
      // setErrorMessage(formatError(error));
    });
  }, []);

  return (
    <Container>

      <OzCard
        headerLeft={
          <CardHeaderTitle title={"Scan the QR Code"} />
        }
        headerSeparator={true}
        footerLeft={<OzButton variant={"chetwodeblue-o"} onClick={() => removeOverlay("mfa")}>Cancel</OzButton>}
        footerRight={
          <OzButton 
            variant={"chetwodeblue-o"} 
            onClick={() => replaceOverlay({
              reference: "mfa",
              content: <ModalMFA3 onSuccess={onSuccess} />,
              isCloseable: false
            }, "mfa")}
          >Next</OzButton>}
      >
        
        <OzRow>
          <OzCol widthmd="6">
            Scan the QR code with your authenticator app.<br/>
            Once the QR code is captured, the app generates a new 6-digit code every 60 seconds.<br/><br/>
            <Cta onClick={() => setShowSecretCode(!showSecretCode)}>{showSecretCode? "Hide" :"Show"} secret code</Cta>
            {showSecretCode ? 
              <SecretCode>{secretCode}</SecretCode>
            :null}
          </OzCol>
          <OzCol widthmd="6">
            <Qr>
              {secretCode ? 
                <QRCodeSVG 
                  size={150}
                  // fgColor={CHETWODEBLUE}
                  value={"otpauth://totp/Ozwol%20Console:"+user.email+"?secret="+secretCode+"&issuer=Ozwol%20Console"} 
                />
              : null }
            </Qr>
            <br/><br/>
          </OzCol>
        </OzRow>

        <MfaWizard step={2} />
{/* 
        <ErrorMessage noMargin>
          {errorMessage}
        </ErrorMessage> */}
      </OzCard>

    </Container>
  );

}

export default ModalMFA2;
